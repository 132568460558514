import React from "react";

import {
  Col,
  Row,
  Card,
  Form,
  Button,
  Nav,
  Tab,
} from "@themesberg/react-bootstrap";

import PageTitle from "../components/PageTitle";

import myAccountData from "../data/myAccount";

const MyAccount = () => {
  return (
    <>
      <PageTitle title="My Account" />
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body>
          <Tab.Container defaultActiveKey="personal-info">
            <Row>
              <Col lg={12}>
                <Nav className="nav-tabs">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="personal-info"
                      className="mb-sm-3 mb-md-0"
                    >
                      Personal Info
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="password" className="mb-sm-3 mb-md-0">
                      Password
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col lg={12}>
                {myAccountData.map((m) => (
                  <Tab.Content className="p-4">
                    <Tab.Pane eventKey="personal-info" className="py-4">
                      <Form>
                        <Row>
                          <Col md={3}>
                            <Form.Group className="mb-4">
                              <Form.Label>First Name</Form.Label>
                              <Form.Control
                                required
                                type="text"
                                value={m.firstName}
                              />
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group className="mb-4">
                              <Form.Label>Last Name</Form.Label>
                              <Form.Control
                                required
                                type="text"
                                value={m.lastName}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={3}>
                            <Form.Group className="mb-4">
                              <Form.Label>Email</Form.Label>
                              <Form.Control
                                required
                                type="email"
                                value={m.email}
                              />
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group className="mb-4">
                              <Form.Label>Phone #</Form.Label>
                              <Form.Control
                                required
                                type="text"
                                value={m.phone}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Form>
                    </Tab.Pane>
                    <Tab.Pane eventKey="password" className="py-4">
                      <Form>
                        <Row>
                          <Col className="mb-4">
                            <h6>Update your password</h6>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={3}>
                            <Form.Group className="mb-4">
                              <Form.Label>Current Password</Form.Label>
                              <Form.Control type="text" />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={3}>
                            <Form.Group className="mb-4">
                              <Form.Label>New Password</Form.Label>
                              <Form.Control type="text" />
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group className="mb-4">
                              <Form.Label>Confirm Password</Form.Label>
                              <Form.Control type="text" />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Form>
                    </Tab.Pane>
                  </Tab.Content>
                ))}
              </Col>
            </Row>
          </Tab.Container>
        </Card.Body>
        <Card.Footer>
          <Row>
            <Col md={12} className="d-flex justify-content-end">
              <Button variant="outline-primary me-3" type="submit">
                Cancel
              </Button>
              <Button variant="primary" type="submit">
                Save
              </Button>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </>
  );
};

export default MyAccount;
