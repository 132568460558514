import React from "react";
import { BiCheck, BiFoodTag } from "react-icons/bi";

import CookieConsent from "react-cookie-consent";

import {
  Col,
  Row,
  Card,
  Image,
  Button,
  Container,
  ListGroup,
  Badge,
  Navbar,
  Nav,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";

import { Routes } from "../routes";
import CtaBgImg from "../assets/img/cta-bg.png";

import subscriptions from "../data/subscriptions";

import LandingPageHeroImg from "../assets/img/hero-laptop.svg";
import ClockIcon from "../assets/img/icons/clock-benefit-icon.svg";
import ChartIcon from "../assets/img/icons/chart-benefit-icon.svg";
import CustomerIcon from "../assets/img/icons/customers-benefit-icon.svg";
import FileIcon from "../assets/img/icons/file-benefit-icon.svg";
import TabletImg from "../assets/img/icons/tablet-features-img.svg";
import IphoneImg from "../assets/img/icons/iphone-features-img.svg";
import FeaturesImg from "../assets/img/landing-features-img.svg";
import DevicesImg from "../assets/img/devices-screens.svg";

import FeatureOneImg from "../assets/img/landing-hero-img.svg";
import AutoGeniLogo from "../assets/img/auto-geni-logo-dark.svg";

export default () => {
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="white"
        variant="light"
        sticky="top"
        className="border-white py-5"
      >
        <Container>
          <Navbar.Brand href="/">
            <Image src={AutoGeniLogo} alt="Welcome to Auto Geni" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav>
              <Nav.Link href="#">Why AutoGeni</Nav.Link>
              <Nav.Link href="#">About Us</Nav.Link>
              <Nav.Link href="#pricing">Pricing</Nav.Link>
            </Nav>
            <Nav>
              <Nav.Link href="#deets">
                <Button
                  variant="outline-primary"
                  size="sm"
                  as={Link}
                  to={Routes.Signin.path}
                >
                  Sign In
                </Button>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <header className="section section-sm bg-soft pb-0" id="hero">
        <Container>
          <Row className="d-flex justify-content-center text-center">
            <Col xs={10}>
              <h6 className="text-primary">Auto shop management software</h6>
              <h1 className="fw-bolder display-2">
                Easy to use and seriously smart.
              </h1>
              <p className="text-muted fw-light my-4 h5">
                A cloud software built to automate shop processes for better
                customer experiences.
              </p>
              <div className="mt-5">
                <Button
                  variant="primary"
                  as={Link}
                  to={Routes.RequestDemo.path}
                  className="me-3"
                >
                  Book Demo
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Image src={LandingPageHeroImg} alt="Hero LandingPage" />
            </Col>
          </Row>
        </Container>
      </header>

      <section className="section section-sm bg-white">
        <Container>
          <Row className="justify-content-center">
            <Col xs={6} md={3} className="text-center">
              <div className=" mb-4">
                <Image src={ClockIcon} width="50" />
              </div>
              <h5>Save precious time</h5>
              <p className="text-gray small">
                Automate estimate requests and maximize profits giving you more
                money and time for essential tasks
              </p>
            </Col>
            <Col xs={6} md={3} className="text-center">
              <div className=" mb-4">
                <Image src={ChartIcon} width="50" />
              </div>
              <h5>See the big picture</h5>
              <p className="text-gray small">
                Get a detailed understanding of your business with reporting and
                insights to help up performance{" "}
              </p>
            </Col>
            <Col xs={6} md={3} className="text-center">
              <div className=" mb-4">
                <Image src={CustomerIcon} width="50" />
              </div>
              <h5>Empower your team</h5>
              <p className="text-gray small">
                Provide effective ways for customer approvals, parts
                procurement, and vehicle inspections through our mobile App
              </p>
            </Col>
            <Col xs={6} md={3} className="text-center">
              <div className=" mb-4">
                <Image src={FileIcon} width="50" />
              </div>
              <h5>Stay organized</h5>
              <p className="text-gray small">
                Manage incoming and outgoing expenses and inventory making sure
                you stay in the green for profits.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="section section-sm bg-white" id="features">
        <Container>
          <h2 className="fw-bolder text-center my-5 h1">
            Some of the features
          </h2>
          <Row className="justify-content-between align-items-center mb-5 mb-lg-7">
            <Col lg={5} className="order-lg-2 mb-5">
              <h3 className="me-5 mb-3">Auto shop management done right</h3>

              <p className="mb-4 display-5 fw-normal">
                AutoGeni simplifies the shop management process by automating
                certain workflows to getting jobs up and running faster and with
                more information.
              </p>

              <Button as={Link} to={Routes.RequestDemo.path} variant="primary">
                Book Demo
              </Button>
            </Col>
            <Col lg={7} className="order-lg-1">
              <Image src={FeatureOneImg} alt="Shop Management" />
            </Col>
          </Row>
          <Row className="justify-content-between align-items-center mb-5 mb-lg-7">
            <Col lg={4}>
              <h2 className=" mb-3 ">
                Everything you need as to manage and service vehicles
                effectively
              </h2>

              <p className="mb-5 display-5 fw-normal">
                AutoGeni's features work together for a seamless all in one
                experience.
              </p>

              <h5 className="me-5 mb-3">Digital vehicle inspections</h5>

              <p className="mb-4">
                Create custom digital templates for common vehicle inspections.
              </p>

              <h5 className="mb-3">Essential reports</h5>
              <p className="mb-4">
                Track shop labor statistics, inventory management, job
                histories, and expense and profit reports.
              </p>

              <Button as={Link} to={Routes.RequestDemo.path} variant="primary">
                Book Demo
              </Button>
            </Col>
            <Col lg={8} className="order-lg-1">
              <Image src={TabletImg} alt="All-In-One Dashboard" />
            </Col>
          </Row>
          <Row className="justify-content-between align-items-center ">
            <Col lg={7}>
              <Image src={FeaturesImg} alt="Some of the features" />
            </Col>
            <Col lg={5}>
              <h2 className=" mb-3 ">
                Detailed and precise for peace of mind.
              </h2>

              <p className="mb-5 display-5 fw-normal">
                No forgotten service forms or signatures access customer service
                histories along with audit trails invoices, authorizations,
                communications, and more.
              </p>

              <h5 className="me-5 mb-3">Verified payment registrations</h5>

              <p className="mb-4">
                Email or text invoices to customers or accept standard payment
                methods in person
              </p>

              <h5 className="mb-3">Built in marketing features</h5>
              <p className="mb-4">
                Capture reviews and export reviews for use in other marketing
                initiatives.
              </p>

              <Button as={Link} to={Routes.RequestDemo.path} variant="primary">
                Book Demo
              </Button>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="section section-sm bg-white" id="devices">
        <Container>
          <Row>
            <Col xs={12}>
              <Image src={DevicesImg} alt="Desktop Tablet & Mobile" />
            </Col>
          </Row>
          <Row className="py-5 ">
            <Col lg={7}>
              <h2>Get an unmatched Shop Management experience</h2>
            </Col>
          </Row>

          <Row className="gx-5">
            <Col lg={4} className="mb-5">
              <h5 className="me-5 mb-3">
                <BiFoodTag className="text-primary me-2" />
                User Access levels
              </h5>
              <p>Grant and revoke access on a user or team-role basis.</p>
            </Col>
            <Col lg={4} className="mb-5">
              <h5 className="me-5 mb-3">
                <BiFoodTag className="text-primary me-2" />
                QuickBooks Integration
              </h5>
              <p>
                Import your previous customers and manage your sales and
                inventory effectively.
              </p>
            </Col>
            <Col lg={4} className="mb-5">
              <h5 className="me-5 mb-3">
                <BiFoodTag className="text-primary me-2" />
                Event reports
              </h5>
              <p>
                All events are recorded for auditing and account history
                tracking.
              </p>
            </Col>
          </Row>
          <Row className="gx-5">
            <Col lg={4} className="mb-5">
              <h5 className="me-5 mb-3">
                <BiFoodTag className="text-primary me-2" />
                Software alerts & notifications
              </h5>
              <p>
                Customized alerts for estimate approvals, customer & mechanic
                communications, vehicle checkouts and more
              </p>
            </Col>
            <Col lg={4} className="mb-5">
              <h5 className="me-5 mb-3">
                <BiFoodTag className="text-primary me-2" />
                Online and in app scheduling
              </h5>
              <p>
                Let customers choose appointment times based on scheduling
                availability and send automated appointment reminders.
              </p>
            </Col>
            <Col lg={4} className="mb-5">
              <h5 className="me-5 mb-3">
                <BiFoodTag className="text-primary me-2" />
                Parts Procurement
              </h5>
              <p>
                Order parts from local and regional parts wholesalers.
                Integrated with NAPPA, O'reilley Auto Parts, and AutoZone.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="section section-sm bg-white " id="mobile-app">
        <Container>
          <Row className=" justify-content-between align-items-center ">
            <Col lg={5}>
              <Image src={IphoneImg} alt="Digital Vehicles Inspections" />
            </Col>
            <Col lg={5}>
              <h2 className="mb-3">Mobile App</h2>

              <p className="mb-4 display-5 fw-normal">
                Communicate with shop manager, add notes, and source parts for
                vehicles all of the time.
              </p>
              <Button as={Link} to={Routes.RequestDemo.path} variant="primary">
                Book Demo
              </Button>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="section section-md bg-soft" id="pricing">
        <Container>
          <Row>
            <Col xs={12} lg={8}>
              <h2 className="mb-3">Subscriptions</h2>
              <p className="lead  me-lg-6">
                Do you want to take your shop to the next level? Check out the
                premium versions in the following comparison table.
              </p>
            </Col>
          </Row>
          <Row className="mt-5 g-1">
            {subscriptions.map((w) => (
              <Col xs={12} md={6} lg={3} className="mb-5 mb-lg-0" key={w.key}>
                <Card border="light">
                  <Card.Header className="bg-white ">
                    <span>
                      <h5 className="text-muted">{w.name}</h5>
                      {w.recommended ? (
                        <Badge
                          bg="success"
                          className="position-absolute top-0 end-0 subscription-badge"
                        >
                          RECOMMENDED
                        </Badge>
                      ) : null}
                      <h2 className="fw-bold display-2">
                        <sup className="h3">$</sup>
                        {w.price}
                        <sup className="h4">/mo.</sup>
                      </h2>
                    </span>
                  </Card.Header>
                  <Card.Body>
                    <ListGroup className="list-group-flush price-list">
                      {w.features.map((item) => (
                        <ListGroup.Item className="border-0 ps-0 bg-transparent">
                          <span className="small">
                            <BiCheck className="text-success me-2" />
                            {item}
                          </span>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                    {w.recommended ? (
                      <Button
                        variant="primary"
                        className="w-100 mb-2 mt-4"
                        as={Link}
                        to={Routes.RequestDemo.path}
                      >
                        Request Demo
                      </Button>
                    ) : (
                      <Button
                        variant="outline-primary"
                        className="w-100 mb-2 mt-4"
                        as={Link}
                        to={Routes.RequestDemo.path}
                      >
                        Request Demo
                      </Button>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      <section
        className="section section-md bg-primary"
        id="cta-section"
        style={{
          backgroundImage: `url(${CtaBgImg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
        }}
      >
        <Container>
          <Row className="justify-content-center text-center text-white">
            <Col xs={12}>
              <h2 className="fw-bold mb-3 h1">Let's work together</h2>
              <p className="lead px-lg-8 mb-5">
                Boost productivity with AutoGeni. The best productivity platform
                for auto shops.
              </p>
              <Button variant="light" as={Link} to={Routes.RequestDemo.path}>
                Request a Demo Today
              </Button>
            </Col>
          </Row>
        </Container>
      </section>

      <footer className="footer bg-soft">
        <Container>
          <Row className="py-6">
            <Col className="mb-md-2 text-center">
              <Card.Link
                href="/"
                className="d-flex justify-content-center mb-4"
              >
                <Image
                  src={AutoGeniLogo}
                  height={53}
                  className="d-block mx-auto "
                  alt="Auto Geni Logo"
                />
              </Card.Link>

              <ul className="list-inline list-group-flush list-group-borderless text-center mb-5">
                <li className="list-inline-item px-0 px-sm-2 ">
                  <Card.Link as={Link} to={Routes.Faqs.path}>
                    FAQs
                  </Card.Link>
                </li>

                <li className="list-inline-item px-0 px-sm-2 ">
                  <Card.Link as={Link} to={Routes.TermsAndConditions.path}>
                    Terms & Conditions
                  </Card.Link>
                </li>
                <li className="list-inline-item px-0 px-sm-2 ">
                  <Card.Link as={Link} to={Routes.PrivacyPolicy.path}>
                    Privacy Policy
                  </Card.Link>
                </li>
              </ul>

              <p className="font-weight-normal font-small text-muted mb-0">
                © AutoGeni, Inc. 2022. All rights reserved
              </p>
            </Col>
          </Row>
        </Container>
      </footer>

      <CookieConsent
        // enableDeclineButton
        // overlay

        debug={true}
        style={{ background: "#262B40" }}
        buttonStyle={{ background: "orange", borderRadius: "4px" }}
        // buttonText="Ok Great!"
        expires={30}
        declineButtonText="No"
        setDeclineCookie={true}
        containerClasses="d-flex justify-content-around align-items-center  rounded-3  "
      >
        <small>
          We use cookies to provide you with the best possible experience. They
          also allow us to analyze user behavior in order to improve the website
          for you. AutoGeni
          <a href="/privacy-policy" className="text-warning">
            {" "}
            Privacy Policy
          </a>{" "}
          for more information.
        </small>
      </CookieConsent>
    </>
  );
};
