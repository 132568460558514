import React from "react";

import {
  Col,
  Row,
  Card,
  Form,
  Button,
  Nav,
  Tab,
  Image,
} from "@themesberg/react-bootstrap";

import PageTitle from "../components/PageTitle";
import PlaceholderImg from "../assets/img/placeholder-img.jpg";

import companyProfileData from "../data/companyProfile";

const ChoosePhotoWidget = (props) => {
  return (
    <div className="d-flex justify-content-start align-items-center gap-4">
      <Image fluid src={PlaceholderImg} />

      <Form.Group controlId="formFile" className="mb-3">
        <Form.Label>
          <h6 className="fw-bold mb-1 ">Upload your brand logo</h6>
          <p>The logo will appear on your app.</p>
        </Form.Label>
        <Form.Control type="file" size="sm" />
        <Form.Text muted className="fw-normal">
          JPG, GIF or PNG. Max size of 800Kb
        </Form.Text>
      </Form.Group>
    </div>
  );
};

const CompanyProfile = () => {
  return (
    <>
      <PageTitle title="Company Profile" />
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body>
          <Tab.Container defaultActiveKey="company-info">
            <Row>
              <Col lg={12}>
                <Nav className="nav-tabs">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="company-info"
                      className="mb-sm-3 mb-md-0"
                    >
                      Company Info
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="brand-colors"
                      className="mb-sm-3 mb-md-0"
                    >
                      Brand & Colors
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col lg={12}>
                <Tab.Content className="p-4">
                  <Tab.Pane eventKey="company-info" className="py-4">
                    {companyProfileData.map((c) => (
                      <Form>
                        <Row>
                          <Col md={3}>
                            <Form.Group className="mb-4">
                              <Form.Label>Business Name</Form.Label>
                              <Form.Control
                                required
                                type="text"
                                value={c.name}
                              />
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group className="mb-4">
                              <Form.Label>How Many Employees?</Form.Label>
                              <Form.Select defaultValue="1">
                                <option value="1">0-10</option>
                                <option value="2">10-50</option>
                                <option value="3">50-100</option>
                              </Form.Select>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={3}>
                            <Form.Group className="mb-4">
                              <Form.Label>Phone #</Form.Label>
                              <Form.Control
                                required
                                type="text"
                                value={c.phone}
                              />
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group className="mb-4">
                              <Form.Label>Location Name or Number</Form.Label>
                              <Form.Control
                                required
                                type="text"
                                value={c.locationName}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <Form.Group className="mb-4">
                              <Form.Label>Address</Form.Label>
                              <Form.Control
                                required
                                type="text"
                                value={c.address.street}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <Row>
                              <Col md={4}>
                                <Form.Group className="mb-4">
                                  <Form.Label>City</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    value={c.address.city}
                                  />
                                </Form.Group>
                              </Col>
                              <Col md={4}>
                                <Form.Group className="mb-4">
                                  <Form.Label>State</Form.Label>
                                  <Form.Select id="state" defaultValue="FL">
                                    <option value="0">State</option>
                                    <option value="AL">Alabama</option>
                                    <option value="AK">Alaska</option>
                                    <option value="AZ">Arizona</option>
                                    <option value="AR">Arkansas</option>
                                    <option value="CA">California</option>
                                    <option value="CO">Colorado</option>
                                    <option value="CT">Connecticut</option>
                                    <option value="DE">Delaware</option>
                                    <option value="DC">
                                      District Of Columbia
                                    </option>
                                    <option value="FL">Florida</option>
                                    <option value="GA">Georgia</option>
                                    <option value="HI">Hawaii</option>
                                    <option value="ID">Idaho</option>
                                    <option value="IL">Illinois</option>
                                    <option value="IN">Indiana</option>
                                    <option value="IA">Iowa</option>
                                    <option value="KS">Kansas</option>
                                    <option value="KY">Kentucky</option>
                                    <option value="LA">Louisiana</option>
                                    <option value="ME">Maine</option>
                                    <option value="MD">Maryland</option>
                                    <option value="MA">Massachusetts</option>
                                    <option value="MI">Michigan</option>
                                    <option value="MN">Minnesota</option>
                                    <option value="MS">Mississippi</option>
                                    <option value="MO">Missouri</option>
                                    <option value="MT">Montana</option>
                                    <option value="NE">Nebraska</option>
                                    <option value="NV">Nevada</option>
                                    <option value="NH">New Hampshire</option>
                                    <option value="NJ">New Jersey</option>
                                    <option value="NM">New Mexico</option>
                                    <option value="NY">New York</option>
                                    <option value="NC">North Carolina</option>
                                    <option value="ND">North Dakota</option>
                                    <option value="OH">Ohio</option>
                                    <option value="OK">Oklahoma</option>
                                    <option value="OR">Oregon</option>
                                    <option value="PA">Pennsylvania</option>
                                    <option value="RI">Rhode Island</option>
                                    <option value="SC">South Carolina</option>
                                    <option value="SD">South Dakota</option>
                                    <option value="TN">Tennessee</option>
                                    <option value="TX">Texas</option>
                                    <option value="UT">Utah</option>
                                    <option value="VT">Vermont</option>
                                    <option value="VA">Virginia</option>
                                    <option value="WA">Washington</option>
                                    <option value="WV">West Virginia</option>
                                    <option value="WI">Wisconsin</option>
                                    <option value="WY">Wyoming</option>
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                              <Col md={4}>
                                <Form.Group className="mb-4">
                                  <Form.Label>Zipcode</Form.Label>
                                  <Form.Control
                                    type="number"
                                    value={c.address.zip}
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Form>
                    ))}
                  </Tab.Pane>
                  <Tab.Pane eventKey="brand-colors" className="py-4">
                    <Form>
                      <Row>
                        <Col md={6}>
                          <ChoosePhotoWidget />
                        </Col>
                      </Row>
                      <Row className="mt-6">
                        <Col md={6}>
                          <Form.Group className="mb-4">
                            <Form.Label htmlFor="brandColorInput">
                              <h6>Select your brand color</h6>
                              <p>
                                Once you select a color, your app primary color
                                will be changed.
                              </p>
                            </Form.Label>
                            <Form.Control
                              type="color"
                              id="brandColorInput"
                              defaultValue="#ff4500"
                              title="Brand primary color"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Card.Body>
        <Card.Footer>
          <Row>
            <Col md={12} className="d-flex justify-content-end">
              <Button variant="outline-primary me-3" type="submit">
                Cancel
              </Button>
              <Button variant="primary" type="submit">
                Save
              </Button>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </>
  );
};

export default CompanyProfile;
