import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faEnvelope,
  faUnlockAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  FormCheck,
  Container,
  InputGroup,
  Image,
} from "@themesberg/react-bootstrap";

import { Routes } from "../routes";
import { Link } from "react-router-dom";
import LoginBgImg from "../assets/img/login-bg.png";
import AutoGeniLogo from "../assets/img/auto-geni-logo-light.svg";

const SignUp = () => {
  return (
    <Container fluid className="min-vh-100 bg-primary p-0">
      <Row>
        <Col
          className="text-white vh-100 p-6 "
          style={{
            backgroundImage: `url(${LoginBgImg})`,
            backgroundSize: "cover",
          }}
        >
          <Image src={AutoGeniLogo} height={71} alt="Auto Geni Logo" />
          <h1 className="display-2 mt-5 w-50">Boost your productivity. </h1>
          <p className="fw-light my-4 h5 w-50">
            Auto Geni stands apart in offering a comprehensive shop management
            system that’s easy to use and comprehend.
          </p>
        </Col>

        <Col className="bg-white p-4">
          <Row>
            <Col>
              <p>
                <Card.Link
                  as={Link}
                  to={Routes.LandingPage.path}
                  className="text-gray-700"
                >
                  <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back
                  Home
                </Card.Link>
              </p>
            </Col>
          </Row>

          <Row>
            <Col>
              <h2>Wizard here</h2>
            </Col>
          </Row>

          <Row>
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="mb-4 mb-lg-0 bg-white p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Create an account</h3>
                </div>
                <Form className="mt-4">
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Your Email</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control
                        autoFocus
                        required
                        type="email"
                        placeholder="example@company.com"
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="password" className="mb-4">
                    <Form.Label>Your Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="password"
                        placeholder="Password"
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="confirmPassword" className="mb-4">
                    <Form.Label>Confirm Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="password"
                        placeholder="Confirm Password"
                      />
                    </InputGroup>
                  </Form.Group>
                  <FormCheck type="checkbox" className="d-flex mb-4">
                    <FormCheck.Input required id="terms" className="me-2" />
                    <FormCheck.Label htmlFor="terms">
                      I agree to the <Card.Link>terms and conditions</Card.Link>
                    </FormCheck.Label>
                  </FormCheck>

                  <Button
                    variant="primary"
                    type="submit"
                    className="w-100"
                    as={Link}
                    to={Routes.Dashboard.path}
                  >
                    Sign up
                  </Button>
                </Form>

                <div className="mt-3 mb-4 text-center">
                  <span className="fw-normal">- or -</span>
                </div>
                <div className="d-flex justify-content-center my-4">
                  <Button
                    variant="outline-secondary"
                    className="fw-normal me-2 w-100"
                  >
                    <FontAwesomeIcon icon={faGoogle} /> Sign Up with Google
                  </Button>
                </div>
                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Already have an account?
                    <Card.Link
                      as={Link}
                      to={Routes.Signin.path}
                      className="fw-bold"
                    >
                      {` Log In here `}
                    </Card.Link>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default SignUp;
