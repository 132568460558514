import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Button,
  Row,
  Col,
  InputGroup,
  Form,
  Card,
  Table,
  Dropdown,
  ButtonGroup,
} from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faSearch,
  faCashRegister,
  faChartLine,
} from "@fortawesome/free-solid-svg-icons";
import { FeaturedCardWidget } from "../components/Widgets";

import PageTitle from "../components/PageTitle";

import workers from "../data/workers";

import { AddWorkerModal, RemoveWorkerModal } from "./components/Modals";
import workerImg from "../assets/img/icons/card-worker.svg";
import workerIcon from "../assets/img/icons/card-worker-icon.svg";
import workerInToday from "../assets/img/icons/card-worker-in-today-icon.svg";

import {
  BiDotsHorizontalRounded,
  BiTrashAlt,
  BiPrinter,
  BiEditAlt,
} from "react-icons/bi";

import {
  CounterWidget,
  CircleChartWidget,
  SalesValueWidgetPhone,
} from "../components/Widgets";

import { trafficShares } from "../data/charts";

const Workers = () => {
  const [modalShow, setModalShow] = useState(false);
  const [removeModalShow, setRemoveModalShow] = useState(false);
  const [items, setItems] = useState(workers);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  function getName(e) {
    setFirstName(e.target.value);
  }

  function getLast(e) {
    setLastName(e.target.value);
  }

  function addItems() {
    const newList = items.concat({
      firstName,
      lastName,
      id: uuidv4(),
    });
    setItems(newList);
    setFirstName("");
    setLastName("");
    setModalShow(false);
    console.log(newList);
  }

  function removeItems(id) {
    const newList = items.filter((r) => r.id !== id);
    setItems(newList);
    setRemoveModalShow(false);
    console.log({ newList });
  }

  return (
    <>
      <PageTitle title="Workers" />
      <Row className="mb-4">
        <Col md={3}>
          <FeaturedCardWidget
            title="Total Workers"
            value={workers.length}
            icon={workerIcon}
            bgImg={workerImg}
          />
        </Col>
        <Col md={3}>
          <FeaturedCardWidget
            title="Working Today"
            value="2"
            icon={workerInToday}
          />
        </Col>
      </Row>

      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body>
          <Row className="justify-content-between align-items-center mb-4">
            <Col className="d-flex gap-3">
              <InputGroup className="w-50">
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>
                <Form.Control type="text" placeholder="Search" />
              </InputGroup>
              <Form.Select className="w-25">
                <option defaultChecked>Show All</option>
                <option value="1">Working Today</option>
                <option value="2">Mecanic</option>
                <option value="3">Manager</option>
                <option value="4">Counterperson</option>
              </Form.Select>
            </Col>
            <Col className="text-right">
              <Button
                variant="outline-primary"
                size="sm"
                onClick={() => setModalShow(true)}
              >
                <FontAwesomeIcon icon={faPlus} className="me-2" /> Add Worker
              </Button>
            </Col>
          </Row>
          <Table responsive className="user-table align-items-center ">
            <thead>
              <tr>
                <th>Name</th>

                <th>Email</th>
                <th>Phone #</th>
                <th>Position</th>
                <th className="text-right">Actions</th>
              </tr>
            </thead>
            <tbody>
              {items.map((w) => (
                <tr key={w.id}>
                  <td>
                    <span className="fw-bold ">
                      <div>{w.firstName + " " + w.lastName}</div>
                    </span>
                  </td>

                  <td>
                    <span>
                      <div>{w.email}</div>
                    </span>
                  </td>
                  <td>
                    <span>
                      <div>{w.phone}</div>
                    </span>
                  </td>
                  <td>
                    <span>
                      <div>{w.position}</div>
                    </span>
                  </td>
                  <td className="text-right fs-2 text-muted">
                    <Dropdown as={ButtonGroup}>
                      <Dropdown.Toggle
                        as={Button}
                        split
                        variant="link"
                        className="text-dark m-0 p-0"
                      >
                        <span className="icon icon-sm ">
                          <BiDotsHorizontalRounded />
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-table-menu">
                        <Dropdown.Item>
                          <BiPrinter className="me-2" /> Print
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <BiEditAlt className="me-2" /> Edit
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="text-danger"
                          onClick={() => setRemoveModalShow(true)}
                        >
                          <BiTrashAlt className="me-2" /> Remove
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                  <RemoveWorkerModal
                    size="md"
                    show={removeModalShow}
                    onHide={() => setRemoveModalShow(false)}
                    removeWorker={() => removeItems(w.id)}
                  />
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <AddWorkerModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        firstName={firstName}
        lastName={lastName}
        onNameChange={getName}
        onLastChange={getLast}
        addWorker={addItems}
        isDisabled={firstName === ""}
      />
    </>
  );
};

export default Workers;
