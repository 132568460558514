import React from "react";

import {
  Col,
  Row,
  Card,
  Container,
  Form,
  Button,
  Nav,
  Tab,
} from "@themesberg/react-bootstrap";

import PageTitle from "../components/PageTitle";

import myAccountData from "../data/myAccount";

const AccountSettings = () => {
  return (
    <>
      <PageTitle title="Account Settings" />
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body>
          <Tab.Container defaultActiveKey="dashboard">
            <Row>
              <Col lg={12}>
                <Nav className="nav-tabs">
                  <Nav.Item>
                    <Nav.Link eventKey="dashboard" className="mb-sm-3 mb-md-0">
                      Dashboard Widgets
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="markup-prices"
                      className="mb-sm-3 mb-md-0"
                    >
                      Markup Prices
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="notifications"
                      className="mb-sm-3 mb-md-0"
                    >
                      Notifications
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col lg={12}>
                <Tab.Content className="p-4">
                  <Tab.Pane eventKey="dashboard" className="py-4">
                    <h6 className=" mb-4">
                      Select the widgets you'd like to see in the dashboard.
                    </h6>
                    <Form>
                      <Row className="gap-3">
                        <Col
                          md={4}
                          className="border d-flex justify-content-between  p-3 "
                        >
                          <Form.Check.Label className="fw-normal">
                            Sales
                          </Form.Check.Label>
                          <Form.Check
                            type="switch"
                            checked
                            id="custom-switch"
                          />
                        </Col>
                        <Col
                          md={4}
                          className="border d-flex justify-content-between  p-3 "
                        >
                          <Form.Check.Label className="fw-normal">
                            Estimates
                          </Form.Check.Label>
                          <Form.Check type="switch" id="custom-switch" />
                        </Col>
                        <Col
                          md={4}
                          className="border d-flex justify-content-between  p-3 "
                        >
                          <Form.Check.Label className="fw-normal">
                            Appointments
                          </Form.Check.Label>
                          <Form.Check type="switch" id="custom-switch" />
                        </Col>
                        <Col
                          md={4}
                          className="border d-flex justify-content-between  p-3 "
                        >
                          <Form.Check.Label className="fw-normal">
                            Jobs In Service
                          </Form.Check.Label>
                          <Form.Check
                            checked
                            type="switch"
                            id="custom-switch"
                          />
                        </Col>
                        <Col
                          md={4}
                          className="border d-flex justify-content-between  p-3 "
                        >
                          <Form.Check.Label className="fw-normal">
                            Jobs Ready for Pickup
                          </Form.Check.Label>
                          <Form.Check type="switch" id="custom-switch" />
                        </Col>
                        <Col
                          md={4}
                          className="border d-flex justify-content-between  p-3 "
                        >
                          <Form.Check.Label className="fw-normal">
                            Jobs Completed
                          </Form.Check.Label>
                          <Form.Check
                            type="switch"
                            checked
                            id="custom-switch"
                          />
                        </Col>
                        <Col
                          md={4}
                          className="border d-flex justify-content-between  p-3 "
                        >
                          <Form.Check.Label className="fw-normal">
                            Reports
                          </Form.Check.Label>
                          <Form.Check
                            checked
                            type="switch"
                            id="custom-switch"
                          />
                        </Col>
                        <Col
                          md={4}
                          className="border d-flex justify-content-between  p-3 "
                        >
                          <Form.Check.Label className="fw-normal">
                            Workers for Today
                          </Form.Check.Label>
                          <Form.Check type="switch" id="custom-switch" />
                        </Col>
                        <Col
                          md={4}
                          className="border d-flex justify-content-between  p-3 "
                        >
                          <Form.Check.Label className="fw-normal">
                            New Customers
                          </Form.Check.Label>
                          <Form.Check type="switch" id="custom-switch" />
                        </Col>
                        <Col
                          md={4}
                          className="border d-flex justify-content-between  p-3 "
                        >
                          <Form.Check.Label className="fw-normal">
                            Parts to Reorder
                          </Form.Check.Label>
                          <Form.Check
                            checked
                            type="switch"
                            id="custom-switch"
                          />
                        </Col>
                      </Row>
                    </Form>
                  </Tab.Pane>
                  <Tab.Pane eventKey="markup-prices" className="py-4">
                    <h6>Least margin percentage</h6>
                    <p className=" mb-4">
                      This is the default percentage value all your parts cost.
                      You can still change individual parts markups when your
                      creating your services.
                    </p>
                    <Form>
                      <Row className="gap-2">
                        <Col
                          md={4}
                          className="border d-flex justify-content-between  p-3 "
                        >
                          <Form.Check.Label className="fw-normal">
                            5%
                          </Form.Check.Label>
                          <Form.Check
                            type="radio"
                            id="radio-1"
                            aria-label="radio 1"
                          />
                        </Col>
                        <Col
                          md={4}
                          className="border d-flex justify-content-between  p-3 "
                        >
                          <Form.Check.Label className="fw-normal">
                            8%
                          </Form.Check.Label>
                          <Form.Check
                            type="radio"
                            id="radio-2"
                            aria-label="radio 2"
                          />
                        </Col>
                        <Col
                          md={4}
                          className="border d-flex justify-content-between  p-3 "
                        >
                          <Form.Check.Label className="fw-normal">
                            10%
                          </Form.Check.Label>
                          <Form.Check
                            type="radio"
                            id="radio-3"
                            aria-label="radio 3"
                          />
                        </Col>
                        <Col
                          md={4}
                          className="border d-flex justify-content-between  p-3 "
                        >
                          <Form.Check.Label className="fw-normal">
                            15%
                          </Form.Check.Label>
                          <Form.Check
                            type="radio"
                            id="radio-4"
                            aria-label="radio 4"
                          />
                        </Col>
                        <Col
                          md={4}
                          className="border d-flex justify-content-between  p-3 "
                        >
                          <Form.Check.Label className="fw-normal">
                            20%
                          </Form.Check.Label>
                          <Form.Check
                            type="radio"
                            id="radio-5"
                            aria-label="radio 5"
                          />
                        </Col>
                      </Row>
                    </Form>
                  </Tab.Pane>
                  <Tab.Pane eventKey="notifications" className="py-4">
                    <h6 className=" mb-4">
                      Select how you would like to be notified.
                    </h6>
                    <Form>
                      <Row className="gap-2">
                        <Col
                          md={4}
                          className="border d-flex justify-content-between  p-3 "
                        >
                          <Form.Check.Label className="fw-normal">
                            By Text Messages
                          </Form.Check.Label>
                          <Form.Check
                            type="switch"
                            checked
                            id="custom-switch"
                          />
                        </Col>
                        <Col
                          md={4}
                          className="border d-flex justify-content-between  p-3 "
                        >
                          <Form.Check.Label className="fw-normal">
                            By Email
                          </Form.Check.Label>
                          <Form.Check
                            checked
                            type="switch"
                            id="custom-switch"
                          />
                        </Col>
                      </Row>
                    </Form>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Card.Body>
        <Card.Footer>
          <Row>
            <Col md={12} className="d-flex justify-content-end">
              <Button variant="outline-primary me-3" type="submit">
                Cancel
              </Button>
              <Button variant="primary" type="submit">
                Save
              </Button>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </>
  );
};

export default AccountSettings;
