import React from "react";

import { BiCheck, BiTrashAlt } from "react-icons/bi";

import {
  Col,
  Row,
  Card,
  Form,
  Button,
  ListGroup,
  Nav,
  Tab,
  Badge,
} from "@themesberg/react-bootstrap";
import subscriptions from "../data/subscriptions";
import creditCards from "../data/creditCards";

import PageTitle from "../components/PageTitle";

import { TransactionsTable } from "../components/Tables";

const SubscriptionPayments = () => {
  return (
    <>
      <PageTitle title="Subscription & Payments" />
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body>
          <Tab.Container defaultActiveKey="Subscriptions">
            <Row>
              <Col lg={12}>
                <Nav className="nav-tabs">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="Subscriptions"
                      className="mb-sm-3 mb-md-0"
                    >
                      Subscriptions
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Payments" className="mb-sm-3 mb-md-0">
                      Payments
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Invoices" className="mb-sm-3 mb-md-0">
                      Invoices
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col lg={12}>
                <Tab.Content className="p-4">
                  <Tab.Pane eventKey="Subscriptions" className="py-4">
                    <Form>
                      <Row>
                        {subscriptions.map((w) => (
                          <Col
                            key={w.key}
                            xs={12}
                            md={6}
                            lg={2}
                            className="mb-5 mb-lg-0 g-0"
                          >
                            <Card
                              border={w.selected ? "primary" : "light"}
                              style={
                                w.selected
                                  ? {
                                      backgroundColor: "#feebe7",
                                      borderWidth: "1px",
                                      borderStyle: "solid",
                                      borderColor: "red",
                                    }
                                  : {}
                              }
                            >
                              <Card.Header>
                                <span>
                                  <h5 className="text-muted">{w.name}</h5>
                                  {w.recommended ? (
                                    <Badge
                                      bg="success"
                                      className="position-absolute top-0 end-0 subscription-badge"
                                    >
                                      RECOMMENDED
                                    </Badge>
                                  ) : null}

                                  <h2 className="fw-bold display-2">
                                    <sup className="h3">$</sup>
                                    {w.price}
                                    <sup className="h4">/mo.</sup>
                                  </h2>
                                </span>
                              </Card.Header>
                              <Card.Body>
                                <ListGroup className="list-group-flush price-list">
                                  {w.features.map((item) => (
                                    <ListGroup.Item className="border-0 ps-0 bg-transparent">
                                      <span className="small">
                                        <BiCheck className="text-success me-2" />
                                        {item}
                                      </span>
                                    </ListGroup.Item>
                                  ))}
                                </ListGroup>
                                {w.selected ? (
                                  <Button
                                    target="_blank"
                                    variant="link"
                                    disabled
                                    className="w-100 mb-2 mt-4 text-primary"
                                  >
                                    Enrolled
                                  </Button>
                                ) : (
                                  <Button
                                    target="_blank"
                                    variant="outline-primary"
                                    className="w-100 mb-2 mt-4"
                                  >
                                    Upgrade
                                  </Button>
                                )}
                              </Card.Body>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    </Form>
                  </Tab.Pane>
                  <Tab.Pane eventKey="Payments" className="py-4">
                    <p>Here you can manage your payment methods.</p>
                    <Form>
                      {creditCards.map((cc) => {
                        return (
                          <Row key={cc.key}>
                            <Col
                              md={6}
                              className="d-flex justify-content-between gap-2 align-items-center"
                            >
                              <div class="pricingCardItem w-100">
                                <input
                                  id="radio1"
                                  name="radio"
                                  type="radio"
                                  checked={cc.default}
                                />
                                <label for="radio1">
                                  {cc.type}
                                  <br />
                                  <span className="small fw-normal">
                                    Debit card ending in...{" "}
                                    <b>{cc.lastFour()}</b>
                                  </span>
                                </label>
                              </div>
                              <BiTrashAlt />
                            </Col>
                          </Row>
                        );
                      })}

                      <Row>
                        <Col md={6}>
                          <Button variant="link">+ Add new card</Button>
                        </Col>
                      </Row>
                    </Form>
                  </Tab.Pane>
                  <Tab.Pane eventKey="Invoices" className="py-4">
                    <TransactionsTable />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Card.Body>
        <Card.Footer>
          <Row>
            <Col md={12} className="d-flex justify-content-end">
              <Button variant="outline-primary me-3" type="submit">
                Cancel
              </Button>
              <Button variant="primary" type="submit">
                Save
              </Button>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </>
  );
};

export default SubscriptionPayments;
