export default [
  {
    id: 1,
    type: "Visa",
    cardName: "John Smith",
    cardNumber: 6512453409851234,
    lastFour: function () {
      return String(this.cardNumber).slice(-4);
    },
    expDate: "05/26",
    cvv: 345,
    address: "1234 SW 12nd Ave",
    city: "Miami",
    state: "Florida",
    zip: 33223,
    default: true,
  },
  {
    id: 2,
    type: "MasterCard",
    cardName: "John Smith",
    cardNumber: 6512453409856789,
    lastFour: function () {
      return String(this.cardNumber).slice(-4);
    },
    expDate: "05/26",
    cvv: 765,
    address: "1234 SW 12nd Ave",
    city: "Miami",
    state: "Florida",
    zip: 33223,
    default: false,
  },
];
