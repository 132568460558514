import Battery from "../assets/img/services/battery.svg";
import Brakes from "../assets/img/services/brakes.svg";
import Balance from "../assets/img/services/balance.svg";
import Oil from "../assets/img/services/oil.svg";
import Colant from "../assets/img/services/colant.svg";
import Wipers from "../assets/img/services/wipers.svg";

export default [
  {
    key: 1,
    image: Battery,
    name: "Battery",
    brand: "Pennzoil",
    desc: "Pennzoil 5W-30 Full Synthetic Motor Oil , 5 Quart",
    itemNumber: 235583,
    vendor: "Napa",
    binLocation: "3B-3",
    cost: "$23.00",
    retail: "$62.00",
    dateCreated: "01/20/2022",
  },
  {
    key: 2,
    image: Brakes,
    name: "Battery",
    brand: "Raybestos",
    desc: "Raybestos Brake Pad Set BPIMGD833MH",
    itemNumber: 67857,
    vendor: "Auto Zone",
    binLocation: "3B-3",
    cost: "$45.00",
    retail: "$85.00",
    dateCreated: "12/02/2022",
  },
  {
    key: 3,
    image: Balance,
    name: "Air Filter",
    brand: "Raybestos",
    desc: "Mobil 1 Extended Performance Oil Filter",
    itemNumber: 54537,
    vendor: "Auto Zone",
    binLocation: "3B-3",
    cost: "$45.00",
    retail: "$85.00",
    dateCreated: "12/02/2022",
  },
  {
    key: 4,
    image: Colant,
    name: "Oil Filter",
    brand: "Mobile",
    desc: "Mobil 1 Extended Performance Oil Filter",
    itemNumber: 54537,
    vendor: "Firestone",
    binLocation: "3B-3",
    cost: "$90.00",
    retail: "$120.00",
    dateCreated: "12/02/2022",
  },
  {
    key: 5,
    image: Brakes,
    name: "Tires 195/60R15",
    brand: "GoodYr",
    desc: "Goodyear Tires Reliant All-Season 195/60R15 88V",
    itemNumber: 54537,
    vendor: "Firestone",
    binLocation: "3B-3",
    cost: "$15.00",
    retail: "$42.00",
    dateCreated: "12/02/2022",
  },
  {
    key: 6,
    image: Wipers,
    name: "Wipers 20in",
    brand: "GoodYr",
    desc: "Wiper Blade 20 Replacement H20",
    itemNumber: 54537,
    vendor: "Auto Zone",
    binLocation: "3B-3",
    cost: "$88.00",
    retail: "$152.00",
    dateCreated: "12/02/2022",
  },
  {
    key: 7,
    image: Oil,
    name: "Oil",
    brand: "GoodYr",
    desc: "Wiper Blade 20 Replacement H20",
    itemNumber: 54537,
    vendor: "Auto Zone",
    binLocation: "3B-3",
    cost: "$88.00",
    retail: "$152.00",
    dateCreated: "12/02/2022",
  },
  {
    key: 8,
    image: Colant,
    name: "Oil Filter",
    brand: "Mobile",
    desc: "Mobil 1 Extended Performance Oil Filter",
    itemNumber: 54537,
    vendor: "Firestone",
    binLocation: "3B-3",
    cost: "$90.00",
    retail: "$120.00",
    dateCreated: "12/02/2022",
  },
  {
    key: 9,
    image: Brakes,
    name: "Tires 195/60R15",
    brand: "GoodYr",
    desc: "Goodyear Tires Reliant All-Season 195/60R15 88V",
    itemNumber: 54537,
    vendor: "Firestone",
    binLocation: "3B-3",
    cost: "$15.00",
    retail: "$42.00",
    dateCreated: "12/02/2022",
  },
  {
    key: 10,
    image: Wipers,
    name: "Wipers 20in",
    brand: "GoodYr",
    desc: "Wiper Blade 20 Replacement H20",
    itemNumber: 54537,
    vendor: "Auto Zone",
    binLocation: "3B-3",
    cost: "$88.00",
    retail: "$152.00",
    dateCreated: "12/02/2022",
  },
];
