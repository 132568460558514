import React, { useState } from "react";
import {
  Row,
  Col,
  InputGroup,
  Form,
  Card,
  Table,
  Image,
} from "@themesberg/react-bootstrap";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";

import { JobsAllStagesWidget } from "../components/Widgets";

import {
  BiDotsHorizontalRounded,
  BiUser,
  BiCar,
  BiSearch,
  BiCalendarAlt,
} from "react-icons/bi";

import PageTitle from "../components/PageTitle";

import jobs from "../data/jobs";

const Jobs = () => {
  const [theDate, setTheDate] = useState("");
  return (
    <>
      <PageTitle title="Jobs" />
      <Row>
        <Col className="mb-4">
          <JobsAllStagesWidget />
        </Col>
      </Row>
      <Card className=" table-wrapper table-responsive ">
        <Card.Body className="card-body-table">
          <Row className="d-flex justify-content-between">
            <Col md={6}>
              <Row>
                <Col md={6}>
                  <Form.Select>
                    <option defaultChecked>Show All</option>
                    <option value="1">Estimated</option>
                    <option value="2">Ready for Service</option>
                    <option value="3">In Service</option>
                    <option value="4">Ready for Pickup</option>
                    <option value="5">Completed</option>
                  </Form.Select>
                </Col>
                <Col md={6}>
                  <InputGroup className="search-input">
                    <InputGroup.Text>
                      <BiSearch />
                    </InputGroup.Text>
                    <Form.Control type="text" placeholder="Search" />
                  </InputGroup>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Form.Group
                id="birthday"
                className="d-flex justify-content-end align-items-center"
              >
                <Datetime
                  timeFormat={false}
                  onChange={setTheDate}
                  renderInput={(props, openCalendar) => (
                    <InputGroup>
                      <InputGroup.Text>
                        <BiCalendarAlt />
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="text"
                        value={
                          theDate ? moment(theDate).format("MM/DD/YYYY") : ""
                        }
                        placeholder="MM/DD/YYYY"
                        onFocus={openCalendar}
                        onChange={() => {}}
                      />
                    </InputGroup>
                  )}
                />
              </Form.Group>
            </Col>
          </Row>

          <Table responsive className="user-table align-items-center mt-4 ">
            <thead>
              <tr>
                <th>Job</th>
                <th>ID #</th>
                <th>Worker</th>
                <th>Due Time</th>
                <th>Status</th>
                <th>Price</th>
                <th className="text-right">Actions</th>
              </tr>
            </thead>
            <tbody>
              {jobs.map((w) => (
                <tr key={w.key}>
                  <td className="fg-2">
                    <div className="d-flex align-items-center gap-2 ">
                      <Image src={w.img} height={50} />
                      <span>
                        <div className="fw-bold">{w.service}</div>
                        <div>
                          <BiCar className="me-2 text-muted" />
                          {w.vehicle}
                        </div>
                        <div>
                          <BiUser className="me-2 text-muted" />
                          {w.customer}
                        </div>
                      </span>
                    </div>
                  </td>
                  <td>
                    <span>
                      <div>{w.jobID}</div>
                    </span>
                  </td>
                  <td>
                    <span>
                      <div>{w.worker}</div>
                    </span>
                  </td>
                  <td>
                    <span>
                      <div>{w.due}</div>
                    </span>
                  </td>
                  <td>
                    <span>{w.status}</span>
                  </td>
                  <td>
                    <span>{w.amount}</span>
                  </td>
                  <td className="text-right fs-3 text-muted">
                    <span>
                      <BiDotsHorizontalRounded />
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
};

export default Jobs;
