import React from "react";
import { faCashRegister, faChartLine } from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "@themesberg/react-bootstrap";

import {
  CounterWidget,
  CircleChartWidget,
  BarChartWidget,
  TeamMembersWidget,
  ProgressTrackWidget,
  RankingWidget,
  SalesValueWidgetPhone,
  SalesValueWidget,
  AcquisitionWidget,
  JobsStagesWidget,
} from "../components/Widgets";
import { PageVisitsTable } from "../components/Tables";
import { trafficShares, totalOrders } from "../data/charts";

import forServiceImg from "../assets/img/icons/car-card-forservice.svg";
import pickupImg from "../assets/img/icons/car-card-pickup.svg";
import carServiceImg from "../assets/img/icons/car-card-inservice.svg";
import readyServiceIcon from "../assets/img/icons/ready-service-icon-card.svg";
import inServiceIcon from "../assets/img/icons/in-service-icon-card.svg";
import readyPickupIcon from "../assets/img/icons/ready-pickup-icon-card.svg";
import estimatedIcon from "../assets/img/icons/estimate-icon-card.svg";
import completedIcon from "../assets/img/icons/completed-icon-card.svg";

import PageTitle from "../components/PageTitle";

export default () => {
  return (
    <>
      <PageTitle title="Dashboard" />
      <Row className="justify-content-md-center">
        <Col className="mb-4">
          <JobsStagesWidget title="Estimated" value="7" icon={estimatedIcon} />
        </Col>
        <Col className="mb-4">
          <JobsStagesWidget
            title="Ready for Service"
            value="8"
            icon={readyServiceIcon}
            bgImg={forServiceImg}
          />
        </Col>
        <Col className="mb-4">
          <JobsStagesWidget
            title="In Service"
            value="4"
            icon={inServiceIcon}
            bgImg={carServiceImg}
          />
        </Col>
        <Col className="mb-4">
          <JobsStagesWidget
            title="Ready for Pickup"
            value="2"
            icon={readyPickupIcon}
            bgImg={pickupImg}
          />
        </Col>
        <Col className="mb-4">
          <JobsStagesWidget title="Completed" value="26" icon={completedIcon} />
        </Col>
      </Row>

      <Row className="justify-content-md-center">
        <Col md={6} className="mb-4">
          <SalesValueWidget
            category="Customers"
            title="Sales"
            value="10,567"
            period="Feb 1 - Apr 1"
            percentage={18.2}
            icon={faChartLine}
            iconColor="shape-secondary"
          />
        </Col>

        <Col md={6} className="mb-4">
          <CounterWidget
            category="Revenue"
            title="$43,594"
            period="Feb 1 - Apr 1"
            percentage={28.4}
            icon={faCashRegister}
            iconColor="shape-tertiary"
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} xl={12} className="mb-4">
          <Row>
            <Col xs={12} xl={8} className="mb-4">
              <Row>
                <Col xs={12} className="mb-4">
                  <PageVisitsTable />
                </Col>

                <Col xs={12} lg={6} className="mb-4">
                  <TeamMembersWidget />
                </Col>

                <Col xs={12} lg={6} className="mb-4">
                  <ProgressTrackWidget />
                </Col>
              </Row>
            </Col>

            <Col xs={12} xl={4}>
              <Row>
                <Col xs={12} className="mb-4">
                  <BarChartWidget
                    title="Total orders"
                    value={452}
                    percentage={18.2}
                    data={totalOrders}
                  />
                </Col>

                <Col xs={12} className="px-0 mb-4">
                  <RankingWidget />
                </Col>

                <Col xs={12} className="px-0">
                  <AcquisitionWidget />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
