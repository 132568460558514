import Battery from "../assets/img/services/battery.svg";

import Balance from "../assets/img/services/balance.svg";
import Oil from "../assets/img/services/oil.svg";
import Colant from "../assets/img/services/colant.svg";
import Wipers from "../assets/img/services/wipers.svg";

export default [
  {
    id: 1,
    service: "Balance",
    img: Balance,
    customer: "John Smith",
    worker: "John Smith",
    vehicle: "2021 Audi A4",
    due: "5 PM",
    amount: "$785",
    method: "Cash on Delivery",
    status: "Estimated",
    jobID: 3423,
  },
  {
    id: 2,
    service: "Oil Change",
    img: Oil,
    customer: "Michael Doe",
    worker: "Michael Doe",
    vehicle: "2021 Audi A4",
    due: "5 PM",
    amount: "$900",
    method: "Online Payment",
    status: "Ready for Service",
    jobID: 424,
  },
  {
    id: 3,
    service: "Battery Replacement",
    img: Battery,
    customer: "John Smith",
    worker: "John Smith",
    vehicle: "2021 Audi A4",
    due: "5 PM",
    amount: "$35",
    method: "Cash on Delivery",
    status: "Ready for Service",
    jobID: 123,
  },
  {
    id: 4,
    service: "Coolant Service",
    img: Colant,
    customer: "Jane Smith",
    worker: "Jane Smith",
    vehicle: "2021 Audi A4",
    due: "5 PM",
    amount: "$920",
    method: "Online",
    status: "In Service",
    jobID: 873,
  },
  {
    id: 5,
    service: "Wipers Installation",
    img: Wipers,
    customer: "Harold Carol",
    worker: "Harold Carol",
    vehicle: "2021 Audi A4",
    due: "5 PM",
    amount: "$2000",
    method: "Online",
    status: "In Service",
    jobID: 656,
  },
  {
    id: 6,
    service: "Oil Change",
    img: Oil,
    customer: "Michael Doe",
    worker: "Michael Doe",
    vehicle: "2021 Audi A4",
    due: "5 PM",
    amount: "$900",
    method: "Online Payment",
    status: "In Service",
    jobID: 424,
  },
  {
    id: 7,
    service: "Battery Replacement",
    img: Battery,
    customer: "John Smith",
    worker: "John Smith",
    vehicle: "2021 Audi A4",
    due: "5 PM",
    amount: "$35",
    method: "Cash on Delivery",
    status: "Completed",
    jobID: 3435,
  },
];
