import React from "react";

import {
  Col,
  Row,
  Card,
  Image,
  Button,
  Container,
  Navbar,
  Nav,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";

import { Routes } from "../routes";
import CtaBgImg from "../assets/img/cta-bg.png";

import ClockIcon from "../assets/img/icons/clock-benefit-icon.svg";
import ChartIcon from "../assets/img/icons/chart-benefit-icon.svg";
import CustomerIcon from "../assets/img/icons/customers-benefit-icon.svg";
import FileIcon from "../assets/img/icons/file-benefit-icon.svg";

import AutoGeniLogo from "../assets/img/auto-geni-logo-dark.svg";

export default () => {
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="white"
        variant="light"
        sticky="top"
        className="border-white py-5"
      >
        <Container>
          <Navbar.Brand as={Link} to={Routes.LandingPage.path}>
            <Image src={AutoGeniLogo} alt="Welcome to Auto Geni" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav>
              <Nav.Link href="#">Why AutoGeni</Nav.Link>
              <Nav.Link href="#">About Us</Nav.Link>
              <Nav.Link href="#pricing">Pricing</Nav.Link>
            </Nav>
            <Nav>
              <Nav.Link href="#deets">
                <Button
                  variant="outline-primary"
                  size="sm"
                  as={Link}
                  to={Routes.Signin.path}
                >
                  Sign In
                </Button>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <header className="section section-sm bg-soft " id="hero">
        <Container>
          <Row className="d-flex justify-content-center text-center">
            <Col xs={10}>
              <h1 className="fw-bolder display-2">Privacy Policy</h1>
            </Col>
          </Row>
        </Container>
      </header>

      <section className="section section-sm bg-white">
        <Container>
          <Row className="justify-content-center">
            <Col xs={6} md={3} className="text-center">
              <div className=" mb-4">
                <Image src={ClockIcon} width="50" />
              </div>
              <h5>Save precious time</h5>
              <p className="text-gray small">
                Automate estimate requests and maximize profits giving you more
                money and time for essential tasks
              </p>
            </Col>
            <Col xs={6} md={3} className="text-center">
              <div className=" mb-4">
                <Image src={ChartIcon} width="50" />
              </div>
              <h5>See the big picture</h5>
              <p className="text-gray small">
                Get a detailed understanding of your business with reporting and
                insights to help up performance{" "}
              </p>
            </Col>
            <Col xs={6} md={3} className="text-center">
              <div className=" mb-4">
                <Image src={CustomerIcon} width="50" />
              </div>
              <h5>Empower your team</h5>
              <p className="text-gray small">
                Provide effective ways for customer approvals, parts
                procurement, and vehicle inspections through our mobile App
              </p>
            </Col>
            <Col xs={6} md={3} className="text-center">
              <div className=" mb-4">
                <Image src={FileIcon} width="50" />
              </div>
              <h5>Stay organized</h5>
              <p className="text-gray small">
                Manage incoming and outgoing expenses and inventory making sure
                you stay in the green for profits.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section
        className="section section-md bg-primary"
        id="cta-section"
        style={{
          backgroundImage: `url(${CtaBgImg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
        }}
      >
        <Container>
          <Row className="justify-content-center text-center text-white">
            <Col xs={12}>
              <h2 className="fw-bold mb-3 h1">Less work together</h2>
              <p className="lead px-lg-8 mb-5">
                Boost productivity with AutoGeni. The best productivity platform
                for auto shops.
              </p>
              <Button variant="light" as={Link} to={Routes.RequestDemo.path}>
                Request a Demo Today
              </Button>
            </Col>
          </Row>
        </Container>
      </section>

      <footer className="footer bg-soft">
        <Container>
          <Row className="py-6">
            <Col className="mb-md-2 text-center">
              <Card.Link
                href="/"
                className="d-flex justify-content-center mb-4"
              >
                <Image
                  src={AutoGeniLogo}
                  height={53}
                  className="d-block mx-auto "
                  alt="Auto Geni Logo"
                />
              </Card.Link>

              <ul className="list-inline list-group-flush list-group-borderless text-center mb-5">
                <li className="list-inline-item px-0 px-sm-2 ">
                  <Card.Link as={Link} to={Routes.Faqs.path}>
                    FAQs
                  </Card.Link>
                </li>

                <li className="list-inline-item px-0 px-sm-2 ">
                  <Card.Link as={Link} to={Routes.TermsAndConditions.path}>
                    Terms & Conditions
                  </Card.Link>
                </li>
                <li className="list-inline-item px-0 px-sm-2 ">
                  <Card.Link as={Link} to={Routes.PrivacyPolicy.path}>
                    Privacy Policy
                  </Card.Link>
                </li>
              </ul>

              <p className="font-weight-normal font-small text-muted mb-0">
                © AutoGeni, Inc. 2022. All rights reserved
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};
