export default [
  {
    key: 1,
    name: "Thomas Rubio",
    email: "thomas@peaky-blinders.com",
    phone: "786-987-0987",
    orders: 23,
    dateCreated: "11/21/2021",
    vehicles: [
      { id: 1, make: "Audi", model: "A8", year: 2022, color: "Blue" },
      { id: 2, make: "Ford", model: "Fusion", year: 1998, color: "White" },
    ],
  },
  {
    key: 2,
    name: "Arthur Morant",
    orders: 45,
    phone: "305-007-5678",
    email: "arthur@peaky-blinders.com",
    dateCreated: "02/12/2021",
    vehicles: [
      { id: 1, make: "Ford", model: "Mustang", year: 1994, color: "Black" },
      { id: 2, make: "Toyota", model: "Corolla", year: 2000, color: "Gray" },
    ],
  },
  {
    key: 3,
    name: "Polly Gray",
    orders: 11,
    email: "polly@peaky-blinders.com",
    phone: "546-877-0980",
    dateCreated: "04/02/2021",
    vehicles: [{ id: 1, make: "Audi", model: "A8", year: 2022, color: "Blue" }],
  },
  {
    key: 4,
    name: "John Sanchez",
    orders: 78,
    email: "john@peaky-blinders.com",
    phone: "876-127-0000",
    dateCreated: "02/12/2021",
    vehicles: [
      { id: 1, make: "Nissan", model: "Altima", year: 2021, color: "Green" },
    ],
  },
  {
    key: 5,
    name: "Grace Walth",
    orders: 3,
    email: "grace@peaky-blinders.com",
    phone: "786-987-0987",
    dateCreated: "02/12/2021",
    vehicles: [{ id: 1, make: "Audi", model: "A8", year: 2022, color: "Blue" }],
  },
  {
    key: 6,
    name: "Polly Gray",
    orders: 76,
    email: "polly@peaky-blinders.com",
    phone: "546-877-0980",
    dateCreated: "04/02/2021",
    vehicles: [
      { id: 1, make: "Ford", model: "Mustang", year: 1994, color: "Black" },
      { id: 2, make: "Toyota", model: "Corolla", year: 2000, color: "Gray" },
    ],
  },
  {
    key: 7,
    name: "John Tinoli",
    orders: 32,
    email: "john@peaky-blinders.com",
    phone: "876-127-0000",
    dateCreated: "02/12/2021",
    vehicles: [
      { id: 1, make: "Honda", model: "Accord", year: 2020, color: "Red" },
    ],
  },
  {
    key: 8,
    name: "Grace Bell",
    orders: 1,
    email: "grace@peaky-blinders.com",
    phone: "786-987-0987",
    dateCreated: "02/12/2021",
    vehicles: [
      { id: 1, make: "Toyota", model: "Corolla", year: 2000, color: "Gray" },
    ],
  },
];
