import Napa from "../assets/img/vendors/napa.jpg";
import Autozone from "../assets/img/vendors/autozone.jpg";
import AdvancedAP from "../assets/img/vendors/advanced-auto-parts.jpg";
import Oreillys from "../assets/img/vendors/oreillys.jpg";

export default [
  {
    key: 1,
    image: Napa,
    name: "Napa",
    url: "www.napa.com",
    agent: "Marco Smoth",
    phone: "768-098-9886",
    email: "napainfo@napa.com",
    status: "Active",
    dateCreated: "01/20/2022",
  },
  {
    key: 2,
    image: Autozone,
    name: "Auto Zone",
    url: "www.autozone.com",
    agent: "John Smith",
    phone: "768-098-98766",
    email: "info@autozone.com",
    status: "Active",
    dateCreated: "10/03/2022",
  },
  {
    key: 3,
    image: AdvancedAP,
    name: "Advanced Auto Parts",
    url: "www.advancedautoparts.com",
    agent: "Sam Othery",
    phone: "875-000-6547",
    email: "info@advancedautoparts.com",
    status: "Active",
    dateCreated: "06/18/2022",
  },
  {
    key: 4,
    image: Oreillys,
    name: "Oreillys",
    url: "www.oreillys.com",
    agent: "Ben Smaarith",
    phone: "654-076-0987",
    email: "sales@oreillys.com",
    status: "Active",
    dateCreated: "01/20/2022",
  },
];
