export default [
  {
    id: 1,
    firstName: "Thomas",
    lastName: "Shelby",
    email: "thomas@peaky-blinders.com",
    phone: "786-987-0987",
    position: "Manager",
    dateCreated: "January 20, 1921",
    workingToday: true,
  },
  {
    id: 2,
    firstName: "Arthur ",
    lastName: "Shelby",
    position: "Counterperson",
    phone: "305-007-5678",
    email: "arthur@peaky-blinders.com",
    dateCreated: "March 20, 1917",
    workingToday: true,
  },
  {
    id: 3,
    firstName: "Luis Alberto",
    lastName: "Bradeas",
    position: "Mecanic",
    email: "polly@peaky-blinders.com",
    phone: "546-877-0980",
    dateCreated: "March 20, 1912",
    workingToday: false,
  },
  {
    id: 4,
    firstName: "Julio",
    lastName: "Santiago",
    position: "Mecanic",
    email: "john@peaky-blinders.com",
    phone: "876-127-0000",
    dateCreated: "December 20, 1924",
    workingToday: false,
  },
];
