import React, { useState } from "react";
import {
  Button,
  Modal,
  Col,
  Row,
  Container,
} from "@themesberg/react-bootstrap";

const NewEstimateModal = (props) => {
  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          New Estimate
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Container>
          <Row>
            <Col xs={12} md={8}>
              .col-xs-12 .col-md-8
            </Col>
            <Col xs={6} md={4}>
              .col-xs-6 .col-md-4
            </Col>
          </Row>

          <Row>
            <Col xs={6} md={4}>
              .col-xs-6 .col-md-4
            </Col>
            <Col xs={6} md={4}>
              .col-xs-6 .col-md-4
            </Col>
            <Col xs={6} md={4}>
              .col-xs-6 .col-md-4
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={props.onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={props.onHide}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

function PageTitle(props) {
  const [modalShow, setModalShow] = useState(false);
  return (
    <div className="py-3 d-flex justify-content-between align-items-center ">
      <div>
        <h4 className="mb-0">{props.title}</h4>
        <p className="mb-0">{props.description}</p>
      </div>
      <Button variant="primary" onClick={() => setModalShow(true)}>
        New Estimate
      </Button>

      <NewEstimateModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
      />
    </div>
  );
}

export default PageTitle;
