import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "../routes";

// pages
import LandingPage from "./LandingPage";
import TermsAndConditions from "./TermsAndConditions";
import PrivacyPolicy from "./PrivacyPolicy";
import Faqs from "./Faqs";
import Upgrade from "./Upgrade";
import Dashboard from "./Dashboard";
import Reports from "./Reports";
import MyAccount from "./MyAccount";
import CompanyProfile from "./CompanyProfile";
import Jobs from "./Jobs";
import Customers from "./Customers";
import ServicesPrices from "./ServicesPrices";
import SubscriptionPayments from "./SubscriptionPayments";
import AccountSettings from "./AccountSettings";
import Vendors from "./Vendors";
import Inventory from "./Inventory";
import Workers from "./Workers";
import BootstrapTables from "./tables/BootstrapTables";
import Signin from "./Signin";
import RequestDemo from "./RequestDemo";
import Signup from "./Signup";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import Lock from "./Lock";
import NotFoundPage from "./NotFound";
import ServerError from "./ServerError";

// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";
import Widgets from "../components/Widgets";
import Forms from "../components/Forms";
import TablesComps from "../components/Tables";

import Accordion from "./components/Accordion";
import Alerts from "./components/Alerts";
import Badges from "./components/Badges";
import Buttons from "./components/Buttons";

// import Forms from "./components/Forms";
import Modals from "./components/Modals";
import Navs from "./components/Navs";
import Navbars from "./components/Navbars";
import Pagination from "./components/Pagination";
import Popovers from "./components/Popovers";
import Progress from "./components/Progress";
import Tables from "./components/Tables";
import Tabs from "./components/Tabs";
import Tooltips from "./components/Tooltips";
import Toasts from "./components/Toasts";

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {" "}
          <Preloader show={loaded ? false : true} /> <Component {...props} />{" "}
        </>
      )}
    />
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Preloader show={loaded ? false : true} />

          <main className="d-flex">
            <Sidebar />

            <div className="right">
              <Navbar />
              <div className="content">
                <Component {...props} />
              </div>

              <Footer />
            </div>
          </main>
        </>
      )}
    />
  );
};

export default () => (
  <Switch>
    <RouteWithLoader
      exact
      path={Routes.LandingPage.path}
      component={LandingPage}
    />

    <RouteWithLoader exact path={Routes.Faqs.path} component={Faqs} />
    <RouteWithLoader
      exact
      path={Routes.PrivacyPolicy.path}
      component={PrivacyPolicy}
    />
    <RouteWithLoader
      exact
      path={Routes.TermsAndConditions.path}
      component={TermsAndConditions}
    />
    <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />

    <RouteWithLoader
      exact
      path={Routes.RequestDemo.path}
      component={RequestDemo}
    />
    <RouteWithLoader exact path={Routes.Signup.path} component={Signup} />
    <RouteWithLoader
      exact
      path={Routes.ForgotPassword.path}
      component={ForgotPassword}
    />
    <RouteWithLoader
      exact
      path={Routes.ResetPassword.path}
      component={ResetPassword}
    />
    <RouteWithLoader exact path={Routes.Lock.path} component={Lock} />
    <RouteWithLoader
      exact
      path={Routes.NotFound.path}
      component={NotFoundPage}
    />
    <RouteWithLoader
      exact
      path={Routes.ServerError.path}
      component={ServerError}
    />

    {/* pages */}
    <RouteWithSidebar
      exact
      path={Routes.Dashboard.path}
      component={Dashboard}
    />
    <RouteWithSidebar exact path={Routes.Upgrade.path} component={Upgrade} />
    <RouteWithSidebar exact path={Routes.Reports.path} component={Reports} />
    <RouteWithSidebar
      exact
      path={Routes.CompanyProfile.path}
      component={CompanyProfile}
    />
    <RouteWithSidebar
      exact
      path={Routes.MyAccount.path}
      component={MyAccount}
    />
    <RouteWithSidebar exact path={Routes.Jobs.path} component={Jobs} />
    <RouteWithSidebar
      exact
      path={Routes.AccountSettings.path}
      component={AccountSettings}
    />
    <RouteWithSidebar
      exact
      path={Routes.SubscriptionPayments.path}
      component={SubscriptionPayments}
    />
    <RouteWithSidebar
      exact
      path={Routes.Customers.path}
      component={Customers}
    />
    <RouteWithSidebar
      exact
      path={Routes.ServicesPrices.path}
      component={ServicesPrices}
    />
    <RouteWithSidebar
      exact
      path={Routes.Inventory.path}
      component={Inventory}
    />
    <RouteWithSidebar exact path={Routes.Workers.path} component={Workers} />
    <RouteWithSidebar exact path={Routes.Vendors.path} component={Vendors} />
    <RouteWithSidebar
      exact
      path={Routes.BootstrapTables.path}
      component={BootstrapTables}
    />

    {/* components */}
    <RouteWithSidebar
      exact
      path={Routes.Accordions.path}
      component={Accordion}
    />
    <RouteWithSidebar exact path={Routes.Alerts.path} component={Alerts} />
    <RouteWithSidebar exact path={Routes.Badges.path} component={Badges} />

    <RouteWithSidebar exact path={Routes.Buttons.path} component={Buttons} />
    <RouteWithSidebar exact path={Routes.Forms.path} component={Forms} />
    <RouteWithSidebar exact path={Routes.Widgets.path} component={Widgets} />
    <RouteWithSidebar exact path={Routes.Modals.path} component={Modals} />
    <RouteWithSidebar exact path={Routes.Navs.path} component={Navs} />
    <RouteWithSidebar exact path={Routes.Navbars.path} component={Navbars} />
    <RouteWithSidebar
      exact
      path={Routes.Pagination.path}
      component={Pagination}
    />
    <RouteWithSidebar exact path={Routes.Popovers.path} component={Popovers} />
    <RouteWithSidebar exact path={Routes.Progress.path} component={Progress} />
    <RouteWithSidebar exact path={Routes.Tables.path} component={Tables} />
    <RouteWithSidebar
      exact
      path={Routes.TablesComps.path}
      component={TablesComps}
    />
    <RouteWithSidebar exact path={Routes.Tabs.path} component={Tabs} />
    <RouteWithSidebar exact path={Routes.Tooltips.path} component={Tooltips} />
    <RouteWithSidebar exact path={Routes.Toasts.path} component={Toasts} />

    <Redirect to={Routes.NotFound.path} />
  </Switch>
);
