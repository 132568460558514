import React from "react";

import {
  Button,
  Row,
  Col,
  Form,
  Container,
  Modal,
} from "@themesberg/react-bootstrap";

// ADD WORKER MODAL
export const NewEstimateModal = (props) => {
  const {
    onLastChange,
    onNameChange,
    lastName,
    firstName,
    onHide,
    addWorker,
    isDisabled,
  } = props;
  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add Worker</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container className="px-0 py-3">
          <Form>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={firstName}
                    onChange={onNameChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={lastName}
                    onChange={onLastChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Phone #</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Label>Position</Form.Label>
                <Form.Select>
                  <option defaultChecked>Mecanic</option>
                  <option value="1">Manager</option>
                  <option value="2">Counterperson</option>
                </Form.Select>
              </Col>
              <Col md={6}>
                <Form.Label>Role</Form.Label>
                <Form.Select>
                  <option defaultChecked>Admin</option>
                  <option value="1">Manager</option>
                  <option value="2">Mecanic</option>
                </Form.Select>
              </Col>
            </Row>
          </Form>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={addWorker} disabled={isDisabled}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

// ADD WORKER MODAL
export const AddWorkerModal = (props) => {
  const {
    onLastChange,
    onNameChange,
    lastName,
    firstName,
    onHide,
    addWorker,
    isDisabled,
  } = props;
  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add Worker</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container className="px-0 py-3">
          <Form>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={firstName}
                    onChange={onNameChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={lastName}
                    onChange={onLastChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Phone #</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Label>Position</Form.Label>
                <Form.Select>
                  <option defaultChecked>Mecanic</option>
                  <option value="1">Manager</option>
                  <option value="2">Counterperson</option>
                </Form.Select>
              </Col>
              <Col md={6}>
                <Form.Label>Role</Form.Label>
                <Form.Select>
                  <option defaultChecked>Admin</option>
                  <option value="1">Manager</option>
                  <option value="2">Mecanic</option>
                </Form.Select>
              </Col>
            </Row>
          </Form>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={addWorker} disabled={isDisabled}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

// REMOVE WORKER MODAL
export const RemoveWorkerModal = (props) => {
  const { onHide, removeWorker } = props;
  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Delete Worker?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>This worker current information will be lost.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-dark" onClick={onHide}>
          Discard
        </Button>
        <Button variant="primary" onClick={removeWorker}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Modals = () => {
  return (
    <>
      <AddWorkerModal />
      <RemoveWorkerModal />
      <NewEstimateModal />
    </>
  );
};

export default Modals;
