import React, { useState } from "react";
import {
  Button,
  Row,
  Col,
  InputGroup,
  Form,
  Card,
  Table,
  Modal,
  Container,
  Image,
} from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";

import PageTitle from "../components/PageTitle";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { FeaturedCardWidget } from "../components/Widgets";

import services from "../data/services";
import serviceImg from "../assets/img/icons/service-card-img.svg";
import inServiceImg from "../assets/img/icons/in-service-icon-card.svg";

// ADD INVENTORY MODAL

const AddServiceModal = (props) => {
  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Service
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container className="px-0 py-3">
          <Form>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Product Name</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Item #</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Bin Location</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Label>Vendor</Form.Label>
                <Form.Select>
                  <option defaultChecked>Auto Zone</option>
                  <option value="1">Napa</option>
                  <option value="2">Auto Parts</option>
                </Form.Select>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Cost</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Retail</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Qty</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={props.onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={props.onHide}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const ServicePrices = () => {
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      <PageTitle title="Services & Prices" />

      <Row className="mb-4">
        <Col md={3}>
          <FeaturedCardWidget
            title="Total Services"
            value={services.length}
            icon={inServiceImg}
            bgImg={serviceImg}
          />
        </Col>
      </Row>

      <Card className="table-wrapper table-responsive">
        <Card.Body className="card-body-table">
          <Row className="justify-content-between align-items-center">
            <Col className="d-flex gap-3">
              <InputGroup className="w-50">
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>
                <Form.Control type="text" placeholder="Search" />
              </InputGroup>
              <Form.Select className="w-25">
                <option defaultChecked>Show All</option>
                <option value="1">Part to Reorder</option>
                <option value="2">Oil Filters</option>
                <option value="3">Batteries</option>
                <option value="4">Wipers</option>
                <option value="5">Tires</option>
                <option value="6">Brake Pads</option>
                <option value="7">Air Filter</option>
              </Form.Select>
            </Col>
            <Col className="text-right">
              <Button
                variant="outline-primary"
                size="sm"
                onClick={() => setModalShow(true)}
              >
                <FontAwesomeIcon icon={faPlus} className="me-2" /> Add Service
              </Button>
            </Col>
          </Row>

          <Table responsive className="user-table align-items-center mt-4">
            <thead>
              <tr>
                <th>Name</th>
                <th>Item #</th>
                <th>Vendor</th>
                <th>Bin Location</th>
                <th>Cost</th>
                <th>Retail</th>
                <th className="text-right">Actions</th>
              </tr>
            </thead>
            <tbody>
              {services.map((w) => (
                <tr key={w.key}>
                  <td>
                    <div className="d-flex align-items-center gap-2 ">
                      <Image src={w.image} height={50} />
                      <div>
                        <span className="fw-bold ">{w.name}</span>
                        <br />
                        <span>{w.desc}</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span>{w.itemNumber}</span>
                  </td>
                  <td>
                    <span>{w.vendor}</span>
                  </td>
                  <td>
                    <span>{w.binLocation}</span>
                  </td>
                  <td>
                    <span>{w.cost}</span>
                  </td>
                  <td>
                    <span>{w.retail}</span>
                  </td>
                  <td className="text-right fs-3 text-muted">
                    <span>
                      <BiDotsHorizontalRounded />
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <AddServiceModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
      />
    </>
  );
};

export default ServicePrices;
