import React, { useState } from "react";
import {
  Button,
  Row,
  Col,
  InputGroup,
  Form,
  Card,
  Table,
  Modal,
  Container,
  Image,
} from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";

import inventory from "../data/inventory";
import PageTitle from "../components/PageTitle";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { FeaturedCardWidget } from "../components/Widgets";

import customerIcon from "../assets/img/icons/card-customer-icon.svg";
import carIcon from "../assets/img/icons/card-car-icon.svg";
import inventoryImg from "../assets/img/icons/inventory-card-img.svg";
import inventoryIcon from "../assets/img/icons/inventory-card-icon.svg";
import costIcon from "../assets/img/icons/cost-card-icon.svg";
import valueIcon from "../assets/img/icons/value-card-icon.svg";

// ADD INVENTORY MODAL

const AddInventoryModal = (props) => {
  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Product
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container className="px-0 py-3">
          <Form>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Product Name</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Item #</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Bin Location</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Label>Vendor</Form.Label>
                <Form.Select>
                  <option defaultChecked>Auto Zone</option>
                  <option value="1">Napa</option>
                  <option value="2">Auto Parts</option>
                </Form.Select>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Cost</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Retail</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Qty</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={props.onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={props.onHide}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Inventory = () => {
  const [modalShow, setModalShow] = useState(false);
  const costSum = inventory.reduce(
    (total, currentItem) => (total = total + currentItem.cost),
    0
  );
  const retailSum = inventory.reduce(
    (total, currentItem) => (total = total + currentItem.retail),
    0
  );

  const qtySum = inventory.reduce(
    (total, currentItem) => (total = total + currentItem.qty),
    0
  );

  const costTotal = costSum * qtySum;

  const retailTotal = retailSum * qtySum;

  return (
    <>
      <PageTitle title="Inventory" />
      <Row className="mb-4">
        <Col md={3}>
          <FeaturedCardWidget
            title="Total Parts"
            value={inventory.length}
            icon={inventoryIcon}
            bgImg={inventoryImg}
            badge="24 To Reorder"
          />
        </Col>
        <Col md={3}>
          <FeaturedCardWidget
            title="Total Cost"
            value={`$ ${costTotal}`}
            icon={costIcon}
          />
        </Col>
        <Col md={3}>
          <FeaturedCardWidget
            title="Total Value"
            value={`$ ${retailTotal}`}
            icon={valueIcon}
          />
        </Col>
      </Row>

      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body>
          <Row className="justify-content-between align-items-center">
            <Col className="d-flex gap-3">
              <InputGroup className="w-50">
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>
                <Form.Control type="text" placeholder="Search" />
              </InputGroup>
              <Form.Select className="w-25">
                <option defaultChecked>Show All</option>
                <option value="1">Part to Reorder</option>
                <option value="2">Oil Filters</option>
                <option value="3">Batteries</option>
                <option value="4">Wipers</option>
                <option value="5">Tires</option>
                <option value="6">Brake Pads</option>
                <option value="7">Air Filter</option>
              </Form.Select>
            </Col>
            <Col className="text-right">
              <Button
                variant="outline-primary"
                size="sm"
                onClick={() => setModalShow(true)}
              >
                <FontAwesomeIcon icon={faPlus} className="me-2" /> Add Part
              </Button>
            </Col>
          </Row>

          <Table responsive className="user-table align-items-center mt-4">
            <thead>
              <tr>
                <th>Name</th>
                <th>Item #</th>
                <th>Vendor</th>
                <th>Bin Location</th>
                <th>Cost</th>
                <th>Retail</th>
                <th>Qty</th>
                <th className="text-right">Actions</th>
              </tr>
            </thead>
            <tbody>
              {inventory.map((w) => (
                <tr key={w.key}>
                  <td>
                    <span className="d-flex align-items-center gap-2">
                      <Image src={w.image} height={50} />
                      <div>
                        <div className="fw-bold ">{w.name}</div>
                        <div>{w.desc}</div>
                      </div>
                    </span>
                  </td>
                  <td>
                    <span>
                      <div>{w.itemNumber}</div>
                    </span>
                  </td>
                  <td>
                    <span>
                      <div>{w.vendor}</div>
                    </span>
                  </td>
                  <td>
                    <span>
                      <div>{w.binLocation}</div>
                    </span>
                  </td>
                  <td>
                    <span>{w.cost}</span>
                  </td>
                  <td>
                    <span>{w.retail}</span>
                  </td>
                  <td>
                    <span>{w.qty}</span>
                  </td>
                  <td className="text-right fs-3 text-muted">
                    <span>
                      <BiDotsHorizontalRounded />
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <AddInventoryModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
      />
    </>
  );
};

export default Inventory;
