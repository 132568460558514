import Battery from "../assets/img/inventory/battery.jpg";
import Brakes from "../assets/img/inventory/brakes.jpg";
import Filter from "../assets/img/inventory/filter.jpg";
import OilFilter from "../assets/img/inventory/oil-filter.jpg";
import Oil from "../assets/img/inventory/oil.jpg";
import Tires from "../assets/img/inventory/tires.jpg";
import Wipers from "../assets/img/inventory/wipers.jpg";

export default [
  {
    id: 1,
    image: Battery,
    name: "Battery",
    brand: "Pennzoil",
    desc: "Pennzoil 5W-30 Full Synthetic Motor Oil , 5 Quart",
    itemNumber: 235583,
    vendor: "Napa",
    binLocation: "3B-3",
    cost: 23,
    retail: 62.5,
    qty: 34,
    dateCreated: "01/20/2022",
  },
  {
    id: 2,
    image: Brakes,
    name: "Battery",
    brand: "Raybestos",
    desc: "Raybestos Brake Pad Set BPIMGD833MH",
    itemNumber: 67857,
    vendor: "Auto Zone",
    binLocation: "3B-3",
    cost: 45.25,
    retail: 85,
    qty: 42,
    dateCreated: "12/02/2022",
  },
  {
    id: 3,
    image: Filter,
    name: "Air Filter",
    brand: "Raybestos",
    desc: "Mobil 1 Extended Performance Oil Filter",
    itemNumber: 54537,
    vendor: "Auto Zone",
    binLocation: "3B-3",
    cost: 45,
    retail: 85,
    qty: 22,
    dateCreated: "12/02/2022",
  },
  {
    id: 4,
    image: OilFilter,
    name: "Oil Filter",
    brand: "Mobile",
    desc: "Mobil 1 Extended Performance Oil Filter",
    itemNumber: 54537,
    vendor: "Firestone",
    binLocation: "3B-3",
    cost: 90,
    retail: 120,
    qty: 83,
    dateCreated: "12/02/2022",
  },
  {
    id: 5,
    image: Tires,
    name: "Tires 195/60R15",
    brand: "GoodYr",
    desc: "Goodyear Tires Reliant All-Season 195/60R15 88V",
    itemNumber: 54537,
    vendor: "Firestone",
    binLocation: "3B-3",
    cost: 15,
    retail: 42,
    qty: 83,
    dateCreated: "12/02/2022",
  },
  {
    id: 6,
    image: Wipers,
    name: "Wipers 20in",
    brand: "GoodYr",
    desc: "Wiper Blade 20 Replacement H20",
    itemNumber: 54537,
    vendor: "Auto Zone",
    binLocation: "3B-3",
    cost: 88,
    retail: 102,
    qty: 123,
    dateCreated: "12/02/2022",
  },
  {
    id: 7,
    image: Oil,
    name: "Oil",
    brand: "GoodYr",
    desc: "Wiper Blade 20 Replacement H20",
    itemNumber: 54537,
    vendor: "Auto Zone",
    binLocation: "3B-3",
    cost: 88,
    retail: 152,
    qty: 123,
    dateCreated: "12/02/2022",
  },
  {
    id: 8,
    image: Filter,
    name: "Air Filter",
    brand: "Raybestos",
    desc: "Mobil 1 Extended Performance Oil Filter",
    itemNumber: 54537,
    vendor: "Auto Zone",
    binLocation: "3B-3",
    cost: 45,
    retail: 85,
    qty: 38,
    dateCreated: "12/02/2022",
  },
  {
    id: 9,
    image: OilFilter,
    name: "Oil Filter",
    brand: "Mobile",
    desc: "Mobil 1 Extended Performance Oil Filter",
    itemNumber: 54537,
    vendor: "Firestone",
    binLocation: "3B-3",
    cost: 90,
    retail: 120,
    qty: 18,
    dateCreated: "12/02/2022",
  },
  {
    id: 10,
    image: Tires,
    name: "Tires 195/60R15",
    brand: "GoodYr",
    desc: "Goodyear Tires Reliant All-Season 195/60R15 88V",
    itemNumber: 54537,
    vendor: "Firestone",
    binLocation: "3B-3",
    cost: 15,
    retail: 42,
    qty: 83,
    dateCreated: "12/02/2022",
  },
  {
    id: 11,
    image: Wipers,
    name: "Wipers 20in",
    brand: "GoodYr",
    desc: "Wiper Blade 20 Replacement H20",
    itemNumber: 54537,
    vendor: "Auto Zone",
    binLocation: "3B-3",
    cost: 88,
    retail: 152,
    qty: 12,
    dateCreated: "12/02/2022",
  },
  {
    key: 12,
    image: Filter,
    name: "Air Filter",
    brand: "Raybestos",
    desc: "Mobil 1 Extended Performance Oil Filter",
    itemNumber: 54537,
    vendor: "Auto Zone",
    binLocation: "3B-3",
    cost: 45,
    retail: 85,
    qty: 21,
    dateCreated: "12/02/2022",
  },
  {
    key: 13,
    image: OilFilter,
    name: "Oil Filter",
    brand: "Mobile",
    desc: "Mobil 1 Extended Performance Oil Filter",
    itemNumber: 54537,
    vendor: "Firestone",
    binLocation: "3B-3",
    cost: 90,
    retail: 120,
    qty: 8,
    dateCreated: "12/02/2022",
  },
  {
    key: 14,
    image: Tires,
    name: "Tires 195/60R15",
    brand: "GoodYr",
    desc: "Goodyear Tires Reliant All-Season 195/60R15 88V",
    itemNumber: 54537,
    vendor: "Firestone",
    binLocation: "3B-3",
    cost: 15,
    retail: 42,
    qty: 8,
    dateCreated: "12/02/2022",
  },
  {
    key: 15,
    image: Wipers,
    name: "Wipers 20in",
    brand: "GoodYr",
    desc: "Wiper Blade 20 Replacement H20",
    itemNumber: 54537,
    vendor: "Auto Zone",
    binLocation: "3B-3",
    cost: 88,
    retail: 152,
    qty: 13,
    dateCreated: "12/02/2022",
  },
];
