import React, { useState } from "react";
import {
  Button,
  Row,
  Col,
  InputGroup,
  Form,
  Card,
  Table,
  Modal,
  Container,
} from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FeaturedCardWidget } from "../components/Widgets";

import PageTitle from "../components/PageTitle";

import { BiCar } from "react-icons/bi";

import customers from "../data/customers";

import { BiDotsHorizontalRounded } from "react-icons/bi";
import customerImg from "../assets/img/icons/card-customer-bg.svg";
import customerIcon from "../assets/img/icons/card-customer-icon.svg";
import carIcon from "../assets/img/icons/card-car-icon.svg";
import forServiceImg from "../assets/img/icons/car-card-forservice.svg";

// ADD WORKER MODAL

const AddWorkerModal = (props) => {
  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Customer
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container className="px-0 py-3">
          <Form>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Phone #</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <h6 className="py-3">Vehicle Info</h6>
              <Col md={4}>
                <Form.Label>Year</Form.Label>
                <Form.Select>
                  <option defaultChecked></option>
                  <option value="1">2021</option>
                  <option value="2">2020</option>
                </Form.Select>
              </Col>
              <Col md={4}>
                <Form.Label>Make</Form.Label>
                <Form.Select>
                  <option defaultChecked></option>
                  <option value="1">Toyota</option>
                  <option value="2">Honda</option>
                </Form.Select>
              </Col>
              <Col md={4}>
                <Form.Label>Model</Form.Label>
                <Form.Select>
                  <option defaultChecked></option>
                  <option value="1">Corolla</option>
                  <option value="2">Camry</option>
                </Form.Select>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Form.Label className="mt-3">Trim</Form.Label>
                <Form.Select>
                  <option defaultChecked></option>
                  <option value="1">2021</option>
                  <option value="2">2020</option>
                </Form.Select>
              </Col>
              <Col md={4}>
                <Form.Group className="mt-3">
                  <Form.Label>Color</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={props.onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={props.onHide}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Customers = () => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <PageTitle title="Customers" />

      <Row className="mb-4">
        <Col md={2}>
          <FeaturedCardWidget
            title="Total Customers"
            value={customers.length}
            icon={customerIcon}
            bgImg={customerImg}
          />
        </Col>
        <Col md={2}>
          <FeaturedCardWidget
            title="Total Vehicles"
            value={customers.length}
            icon={carIcon}
            bgImg={forServiceImg}
          />
        </Col>
      </Row>

      <Card className=" table-wrapper table-responsive ">
        <Card.Body className="card-body-table">
          <Row className="justify-content-between align-items-center">
            <Col className="d-flex gap-3">
              <InputGroup className="w-50">
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>
                <Form.Control type="text" placeholder="Search" />
              </InputGroup>
              <Form.Select className="w-25">
                <option defaultChecked>Show All</option>
                <option value="1">Working Today</option>
                <option value="2">Mecanic</option>
                <option value="3">Manager</option>
                <option value="3">Counterperson</option>
              </Form.Select>
            </Col>
            <Col className="text-right">
              <Button
                variant="outline-primary"
                size="sm"
                onClick={() => setModalShow(true)}
              >
                <FontAwesomeIcon icon={faPlus} className="me-2" /> Add Customer
              </Button>
            </Col>
          </Row>

          <Table responsive className="user-table align-items-center mt-4">
            <thead>
              <tr>
                <th>Name/Vehicle</th>
                <th>Email</th>
                <th>Phone #</th>
                <th>Orders</th>
                <th>Date Created</th>
                <th className="text-right">Actions</th>
              </tr>
            </thead>
            <tbody>
              {customers.map((w) => (
                <tr key={w.key}>
                  <td>
                    <span className="fw-bold">
                      <div className="text-gray">{w.name}</div>
                      {w.vehicles.map((item) => (
                        <div className="text-gray fw-normal small d-flex align-items-center">
                          <BiCar className="me-2 text-muted" />
                          <span>
                            {item.make} {item.model} {item.year} {item.color}
                          </span>
                        </div>
                      ))}
                    </span>
                  </td>
                  <td>
                    <span className="fw-normal">
                      <div className="text-gray">{w.email}</div>
                    </span>
                  </td>
                  <td>
                    <span className="fw-normal">
                      <div className="text-gray">{w.phone}</div>
                    </span>
                  </td>
                  <td>
                    <span className="fw-normal">
                      <div className="text-gray">{w.orders}</div>
                    </span>
                  </td>
                  <td>
                    <span className="fw-normal">{w.dateCreated}</span>
                  </td>
                  <td className="text-right fs-3 text-muted">
                    <span>
                      <BiDotsHorizontalRounded />
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <AddWorkerModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
      />
    </>
  );
};

export default Customers;
