import React from "react";

import { TransactionsTable } from "../components/Tables";
import PageTitle from "../components/PageTitle";

const Reports = () => {
  return (
    <>
      <PageTitle title="Reports" />

      <TransactionsTable />
    </>
  );
};

export default Reports;
