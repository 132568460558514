import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  Container,
  Image,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import CtaBgImg from "../assets/img/cta-bg.png";

import AutoGeniLogo from "../assets/img/auto-geni-logo-light.svg";

import { Routes } from "../routes";

export default () => {
  return (
    <Container fluid className="p-md-0 m-md-0 ">
      <Row
        style={{
          backgroundImage: `url(${CtaBgImg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
        }}
      >
        <Col xs={12} className="text-white text-center py-6">
          <Image src={AutoGeniLogo} height={71} alt="Auto Geni Logo" />
          <h1 className="fw-bold my-4">Request a Demo</h1>
          <p className="lead px-lg-8 mb-5">
            Boost productivity with AutoGeni. The best productivity platform for
            auto shops.
          </p>
        </Col>
      </Row>
      <Row className="d-flex mx-auto align-items-center justify-content-center">
        <Col md={8} lg={4}>
          <Card
            className="border bg-white p-4"
            style={{
              marginTop: "-50px",
            }}
          >
            <Form>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control type="text" />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control type="text" />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Phone #</Form.Label>
                    <Form.Control type="text" />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Company Name</Form.Label>
                    <Form.Control type="text" />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Number of Employees</Form.Label>
                    <Form.Select>
                      <option defaultChecked></option>
                      <option value="1">0-10</option>
                      <option value="2">10-50</option>
                      <option value="2">50-200</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Button
                size="lg"
                variant="primary"
                type="submit"
                className="w-100"
                as={Link}
                to={Routes.Dashboard.path}
              >
                Request Demo
              </Button>
            </Form>
            <p className="text-center mt-6">
              <Card.Link
                as={Link}
                to={Routes.LandingPage.path}
                className="text-gray-700"
              >
                <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back
                Home
              </Card.Link>
            </p>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
