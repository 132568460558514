import React from "react";
import { BiCheck, BiDollarCircle, BiStar, BiSitemap } from "react-icons/bi";
import subscriptions from "../data/subscriptions";
import {
  Col,
  Row,
  Card,
  Image,
  Button,
  Container,
  Navbar,
  Nav,
  Accordion,
  Badge,
  ListGroup,
  Tab,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";

import { Routes } from "../routes";
import CtaBgImg from "../assets/img/cta-bg.png";

import AutoGeniLogo from "../assets/img/auto-geni-logo-dark.svg";

export default () => {
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="white"
        variant="light"
        sticky="top"
        className="border-white py-5"
      >
        <Container>
          <Navbar.Brand as={Link} to={Routes.LandingPage.path}>
            <Image src={AutoGeniLogo} alt="Welcome to Auto Geni" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav>
              <Nav.Link href="#">Why AutoGeni</Nav.Link>
              <Nav.Link href="#">About Us</Nav.Link>
              <Nav.Link href="#pricing">Pricing</Nav.Link>
            </Nav>
            <Nav>
              <Nav.Link href="#deets">
                <Button
                  variant="outline-primary"
                  size="sm"
                  as={Link}
                  to={Routes.Signin.path}
                >
                  Sign In
                </Button>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <header className="section section-sm bg-white pb-0" id="hero">
        <Container>
          <Row className="d-flex justify-content-center text-center">
            <Col xs={10}>
              <h1 className="fw-bolder ">Frequently Asked Questions</h1>
              <p className="text-muted fw-light  h5">
                Choose a category to find the help you need.
              </p>
            </Col>
          </Row>
        </Container>
      </header>

      <section className="section section-lg pt-6 bg-white">
        <Container>
          <Row>
            <Col>
              <Tab.Container defaultActiveKey="pricing">
                <Row className="mb-4">
                  <Col lg={12} className="d-flex justify-content-center">
                    <Nav variant="pills">
                      <Nav.Item>
                        <Nav.Link eventKey="pricing" className="text-start">
                          <BiDollarCircle className="me-2" /> Pricing
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="integrations"
                          className="text-start"
                        >
                          <BiSitemap className="me-2" /> Integrations
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="pe-3">
                        <Nav.Link eventKey="features" className="text-start">
                          <BiStar className="me-2" /> Features
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                  <Col lg={9}>
                    <Card className="p-4">
                      <Tab.Content>
                        <Tab.Pane eventKey="pricing">
                          <Accordion flush>
                            <Accordion.Item eventKey="0">
                              <Accordion.Header>
                                Can I pick and choose a la carte style
                                editions/functionality?
                              </Accordion.Header>
                              <Accordion.Body>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat. Duis aute irure dolor in
                                reprehenderit in voluptate velit esse cillum
                                dolore eu fugiat nulla pariatur. Excepteur sint
                                occaecat cupidatat non proident, sunt in culpa
                                qui officia deserunt mollit anim id est laborum.
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                              <Accordion.Header>
                                Accordion Item #2
                              </Accordion.Header>
                              <Accordion.Body>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat. Duis aute irure dolor in
                                reprehenderit in voluptate velit esse cillum
                                dolore eu fugiat nulla pariatur. Excepteur sint
                                occaecat cupidatat non proident, sunt in culpa
                                qui officia deserunt mollit anim id est laborum.
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                              <Accordion.Header>
                                Can I pick and choose a la carte style
                                editions/functionality?
                              </Accordion.Header>
                              <Accordion.Body>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat. Duis aute irure dolor in
                                reprehenderit in voluptate velit esse cillum
                                dolore eu fugiat nulla pariatur. Excepteur sint
                                occaecat cupidatat non proident, sunt in culpa
                                qui officia deserunt mollit anim id est laborum.
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                              <Accordion.Header>
                                Accordion Item #2
                              </Accordion.Header>
                              <Accordion.Body>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat. Duis aute irure dolor in
                                reprehenderit in voluptate velit esse cillum
                                dolore eu fugiat nulla pariatur. Excepteur sint
                                occaecat cupidatat non proident, sunt in culpa
                                qui officia deserunt mollit anim id est laborum.
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                              <Accordion.Header>
                                Can I pick and choose a la carte style
                                editions/functionality?
                              </Accordion.Header>
                              <Accordion.Body>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat. Duis aute irure dolor in
                                reprehenderit in voluptate velit esse cillum
                                dolore eu fugiat nulla pariatur. Excepteur sint
                                occaecat cupidatat non proident, sunt in culpa
                                qui officia deserunt mollit anim id est laborum.
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5">
                              <Accordion.Header>
                                Accordion Item #2
                              </Accordion.Header>
                              <Accordion.Body>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat. Duis aute irure dolor in
                                reprehenderit in voluptate velit esse cillum
                                dolore eu fugiat nulla pariatur. Excepteur sint
                                occaecat cupidatat non proident, sunt in culpa
                                qui officia deserunt mollit anim id est laborum.
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </Tab.Pane>
                        <Tab.Pane eventKey="integrations" className="py-4">
                          <p>
                            Photo booth stumptown tote bag Banksy, elit small
                            batch freegan sed. Craft beer elit seitan
                            exercitation, photo booth et 8-bit kale chips
                            proident chillwave deep v laborum. Aliquip veniam
                            delectus, Marfa eiusmod Pinterest in do umami
                            readymade swag.
                          </p>
                          <p>
                            Day handsome addition horrible sensible goodness two
                            contempt. Evening for married his account removal.
                            Estimable me disposing of be moonlight cordially
                            curiosity.
                          </p>
                        </Tab.Pane>
                        <Tab.Pane eventKey="features" className="py-4">
                          <p>
                            Exercitation photo booth stumptown tote bag Banksy,
                            elit small batch freegan sed. Craft beer elit seitan
                            exercitation, photo booth et 8-bit kale chips
                            proident chillwave deep v laborum. Aliquip veniam
                            delectus, Marfa eiusmod Pinterest in do umami
                            readymade swag.
                          </p>
                          <p>
                            Day handsome addition horrible sensible goodness two
                            contempt. Evening for married his account removal.
                            Estimable me disposing of be moonlight cordially
                            curiosity.
                          </p>
                        </Tab.Pane>
                      </Tab.Content>
                    </Card>
                  </Col>
                </Row>
              </Tab.Container>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="section section-md bg-soft" id="pricing">
        <Container>
          <Row>
            <Col xs={12} lg={8}>
              <h2 className="mb-3">Subscriptions</h2>
              <p className="lead  me-lg-6">
                Do you want to take your shop to the next level? Check out the
                premium versions in the following comparison table.
              </p>
            </Col>
          </Row>
          <Row className="mt-5 g-1">
            {subscriptions.map((w) => (
              <Col xs={12} md={6} lg={3} className="mb-5 mb-lg-0" key={w.key}>
                <Card border="light">
                  <Card.Header className="bg-white ">
                    <span>
                      <h5 className="text-muted">{w.name}</h5>
                      {w.recommended ? (
                        <Badge
                          bg="success"
                          className="position-absolute top-0 end-0 subscription-badge"
                        >
                          RECOMMENDED
                        </Badge>
                      ) : null}
                      <h2 className="fw-bold display-2">
                        <sup className="h3">$</sup>
                        {w.price}
                        <sup className="h4">/mo.</sup>
                      </h2>
                    </span>
                  </Card.Header>
                  <Card.Body>
                    <ListGroup className="list-group-flush price-list">
                      {subscriptions.map((w) => (
                        <ListGroup.Item className="bg-white border-0 ps-0">
                          <span>
                            <BiCheck className="text-success me-2" />
                            {w.features[0]}
                          </span>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                    {w.recommended ? (
                      <Button
                        variant="primary"
                        className="w-100 mb-2 mt-4"
                        as={Link}
                        to={Routes.RequestDemo.path}
                      >
                        Request Demo
                      </Button>
                    ) : (
                      <Button
                        variant="outline-primary"
                        className="w-100 mb-2 mt-4"
                        as={Link}
                        to={Routes.RequestDemo.path}
                      >
                        Request Demo
                      </Button>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      <section
        className="section section-md bg-primary"
        id="cta-section"
        style={{
          backgroundImage: `url(${CtaBgImg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
        }}
      >
        <Container>
          <Row className="justify-content-center text-center text-white">
            <Col xs={12}>
              <h2 className="fw-bold mb-3 h1">Let's work together</h2>
              <p className="lead px-lg-8 mb-5">
                Boost productivity with AutoGeni. The best productivity platform
                for auto shops.
              </p>
              <Button variant="light" as={Link} to={Routes.RequestDemo.path}>
                Request a Demo Today
              </Button>
            </Col>
          </Row>
        </Container>
      </section>

      <footer className="footer bg-soft">
        <Container>
          <Row className="py-6">
            <Col className="mb-md-2 text-center">
              <Card.Link
                href="/"
                className="d-flex justify-content-center mb-4"
              >
                <Image
                  src={AutoGeniLogo}
                  height={53}
                  className="d-block mx-auto "
                  alt="Auto Geni Logo"
                />
              </Card.Link>

              <ul className="list-inline list-group-flush list-group-borderless text-center mb-5">
                <li className="list-inline-item px-0 px-sm-2 ">
                  <Card.Link as={Link} to={Routes.Faqs.path}>
                    FAQs
                  </Card.Link>
                </li>

                <li className="list-inline-item px-0 px-sm-2 ">
                  <Card.Link as={Link} to={Routes.TermsAndConditions.path}>
                    Terms & Conditions
                  </Card.Link>
                </li>
                <li className="list-inline-item px-0 px-sm-2 ">
                  <Card.Link as={Link} to={Routes.PrivacyPolicy.path}>
                    Privacy Policy
                  </Card.Link>
                </li>
              </ul>

              <p className="font-weight-normal font-small text-muted mb-0">
                © AutoGeni, Inc. 2022. All rights reserved
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};
