import React, { useState } from "react";
import {
  Button,
  Row,
  Col,
  Form,
  Card,
  Table,
  Modal,
  Container,
  Image,
} from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import vendors from "../data/vendors";
import PageTitle from "../components/PageTitle";

import { BiDotsHorizontalRounded } from "react-icons/bi";

// ADD INVENTORY MODAL

const AddVendorModal = (props) => {
  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add Vendor</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container className="px-0 py-3">
          <Form>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Url <span className="small ">(optional)</span>
                  </Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Account #</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Address</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>City</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>State</Form.Label>
                  <Form.Select>
                    <option defaultChecked></option>
                    <option value="1">-</option>
                    <option value="2">-</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Zipcode</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="py-4">
                <h6>Contact Info</h6>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Phone #</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={props.onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={props.onHide}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Vendors = () => {
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      <PageTitle title="Vendors" />

      <Card className="table-wrapper table-responsive ">
        <Card.Body className="card-body-table">
          <Row className="justify-content-between align-items-center">
            <Col className="text-right">
              <Button
                variant="outline-primary"
                size="sm"
                onClick={() => setModalShow(true)}
              >
                <FontAwesomeIcon icon={faPlus} className="me-2" /> Add Vendor
              </Button>
            </Col>
          </Row>

          <Table responsive className="user-table align-items-center mt-4 ">
            <thead>
              <tr>
                <th>Name</th>
                <th>Agent</th>
                <th>Phone #</th>
                <th>Email</th>
                <th>Status</th>
                <th className="text-right">Actions</th>
              </tr>
            </thead>
            <tbody>
              {vendors.map((w) => (
                <tr key={w.key}>
                  <td>
                    <span className="d-flex align-items-center gap-2">
                      <Image src={w.image} height={80} />
                      <div>
                        <div className="text-gray fw-bold ">{w.name}</div>
                      </div>
                    </span>
                  </td>
                  <td>
                    <span className="fw-normal">
                      <div className="text-gray">{w.agent}</div>
                    </span>
                  </td>
                  <td>
                    <span className="fw-normal">
                      <div className="text-gray">{w.phone}</div>
                    </span>
                  </td>
                  <td>
                    <span className="fw-normal">
                      <div className="text-gray">{w.email}</div>
                    </span>
                  </td>
                  <td>
                    <span className="fw-normal">{w.status}</span>
                  </td>
                  <td className="text-right fs-3 text-muted">
                    <span>
                      <BiDotsHorizontalRounded />
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <AddVendorModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
      />
    </>
  );
};

export default Vendors;
