export default [
  {
    id: 1,
    name: "Miami AutoShop",
    phone: "305-123-4568",
    locationName: "Miami Store #2348",
    address: {
      street: "123 SW 38th St",
      city: "Miami",
      state: "FL",
      zip: "12348",
    },
    employeeQty: {
      starter: "0-10",
      growing: "10-50",
      premier: "50-200",
      enterprise: "200+",
    },
  },
];
