import React from "react";
import { Image } from "@themesberg/react-bootstrap";

import AutoGeniLogo from "../assets/img/favicon/loader.gif";

export default (props) => {
  const { show } = props;

  return (
    <div
      className={`preloader bg-soft flex-column justify-content-center align-items-center ${
        show ? "" : "show"
      }`}
    >
      <Image className="loader-element " src={AutoGeniLogo} height={40} />
    </div>
  );
};
