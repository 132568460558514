import React, { useState } from "react";

import {
  Row,
  Col,
  Nav,
  Image,
  Navbar,
  Dropdown,
  Container,
  ListGroup,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import { Routes } from "../routes";

import {
  BiBell,
  BiUserCircle,
  BiStore,
  BiCreditCard,
  BiBarChartSquare,
  BiLogOut,
  BiPurchaseTagAlt,
  BiChevronDown,
  BiCog,
} from "react-icons/bi";

import NOTIFICATIONS_DATA from "../data/notifications";
import Profile3 from "../assets/img/team/profile-picture-2.jpg";

export default (props) => {
  const [notifications, setNotifications] = useState(NOTIFICATIONS_DATA);
  const areNotificationsRead = notifications.reduce(
    (acc, notif) => acc && notif.read,
    true
  );

  const markNotificationsAsRead = () => {
    setTimeout(() => {
      setNotifications(notifications.map((n) => ({ ...n, read: true })));
    }, 300);
  };

  const Notification = (props) => {
    const { link, sender, time, message, read = false } = props;
    const readClassName = read ? "" : "text-danger";

    return (
      <ListGroup.Item action href={link} className="border-bottom border-light">
        <Row className="align-items-center">
          <Col className="ps-0">
            <div className="d-flex justify-content-between align-items-center p-2">
              <div>
                <h6 className=" mb-0 small fw-bold">{sender}</h6>
              </div>
              <div className="text-end text-lightgray">
                <small className={readClassName}>{time}</small>
              </div>
            </div>
            <p className="font-small px-2 text-gray fw-normal m-0">{message}</p>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  };

  return (
    <Navbar bg="white" variant="light" expanded>
      <Container fluid className="p-0">
        <Nav className=" d-flex justify-content-end align-items-center w-100">
          <Dropdown as={Nav.Item} onToggle={markNotificationsAsRead}>
            <Dropdown.Toggle
              as={Nav.Link}
              className="text-dark icon-notifications mt-2 me-4"
            >
              <span className="icon icon-sm  ">
                <BiBell />
                {areNotificationsRead ? null : (
                  <span className="icon-badge rounded-circle unread-notifications" />
                )}
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-center notifications-dropdown dropdown-menu-lg">
              <ListGroup>
                <Nav.Link
                  href="#"
                  className="text-primary fw-bold border-bottom border-light py-3"
                >
                  Notifications
                </Nav.Link>

                {notifications.map((n) => (
                  <Notification key={`notification-${n.id}`} {...n} />
                ))}

                <Dropdown.Item className="text-center text-primary fw-bold py-3">
                  View all
                </Dropdown.Item>
              </ListGroup>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown as={Nav.Item}>
            <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
              <div className="media d-flex align-items-center">
                <Image
                  src={Profile3}
                  className="user-avatar md-avatar rounded-circle"
                />
                <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                  <span className="mb-0 font-small fw-bold">
                    <BiChevronDown className="me-2" />
                  </span>
                </div>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
              <Dropdown.Item>
                <Link to={Routes.MyAccount.path}>
                  <BiUserCircle className="me-2" />
                  My Account
                </Link>
              </Dropdown.Item>
              <Dropdown.Item>
                <Link to={Routes.CompanyProfile.path}>
                  <BiStore className="me-2" /> Company Profile
                </Link>
              </Dropdown.Item>
              <Dropdown.Item>
                <Link to={Routes.Vendors.path}>
                  <BiPurchaseTagAlt className="me-2" />
                  Vendors
                </Link>
              </Dropdown.Item>
              <Dropdown.Item>
                <Link to={Routes.SubscriptionPayments.path}>
                  <BiCreditCard className="me-2" />
                  Subscription & Payments
                </Link>
              </Dropdown.Item>

              <Dropdown.Item>
                <Link to={Routes.Reports.path}>
                  <BiBarChartSquare className="me-2" />
                  Reports
                </Link>
              </Dropdown.Item>

              <Dropdown.Item>
                <Link to={Routes.AccountSettings.path}>
                  <BiCog className="me-2" />
                  Account Settings
                </Link>
              </Dropdown.Item>

              <Dropdown.Divider className="bg-dark" />

              <Dropdown.Item>
                <Link to={Routes.LandingPage.path} className="text-danger">
                  <BiLogOut className="me-2 text-danger" />
                  Logout
                </Link>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Nav>
      </Container>
    </Navbar>
  );
};
