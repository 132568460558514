export default [
  {
    id: 1,
    firstName: "Alian",
    lastName: "Morales",
    email: "alian@gmail.com",
    phone: "305-123-4568",
    locationName: "Miami Store #2348",
    password: "********",
    confirmPassword: "********",
  },
];
