export default [
  {
    id: 1,
    name: "Starter",
    price: 99,
    features: [
      "Estimates & Invoicing",
      "Unlimited SMS & Email",
      "Payment Processing",
      "Mobile App Access",
      "Detailed Reporting",
    ],
    recommended: false,
    selected: true,
  },
  {
    id: 2,
    name: "Growing",
    price: 225,
    features: [
      "Inventory Management",
      "Employee Scheduling",
      "QuickBooks Integration",
      "Flexible Workflows",
      "Vehicle Inspections",
    ],
    recommended: true,
    selected: false,
  },
  {
    id: 3,
    name: "Premier",
    price: 399,
    features: [
      "Review captures",
      "Automated Service Notices",
      "Parts Procurement",
      "Tire Ordering",
      "Diagrams & Procedures",
    ],
    recommended: false,
    selected: false,
  },
  {
    id: 4,
    name: "Enterprise",
    price: 0,
    features: [
      "Bulk Licensing",
      "Account Manager",
      "Free Onboarding",
      "Data Migration *",
      "Implementation Plan",
    ],
    recommended: false,
    selected: false,
  },
];
