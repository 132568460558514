import React from "react";
import moment from "moment-timezone";
import { Row, Col, Card } from "@themesberg/react-bootstrap";

export default (props) => {
  const currentYear = moment().get("year");

  return (
    <div>
      <footer className="footer section py-3">
        <Row>
          <Col className="mb-4 mb-lg-0">
            <p className="mb-0 text-right text-muted small">
              Copyright © {`${currentYear} `}
              <Card.Link
                href="#"
                target="_blank"
                className=" text-dark text-decoration-none "
              >
                AutoGeni
              </Card.Link>
            </p>
          </Col>
        </Row>
      </footer>
    </div>
  );
};
