export const Routes = {
  // pages
  LandingPage: { path: "/" },
  TermsAndConditions: { path: "/terms-and-conditions" },
  AccountSettings: { path: "/system-preferences" },
  PrivacyPolicy: { path: "/privacy-policy" },
  Faqs: { path: "/faqs" },
  Dashboard: { path: "/dashboard" },
  Reports: { path: "/reports" },
  MyAccount: { path: "/my-account" },
  CompanyProfile: { path: "/company-profile" },
  Upgrade: { path: "/upgrade" },
  Jobs: { path: "/jobs" },
  Customers: { path: "/customers" },
  ServicesPrices: { path: "/services-prices" },
  SubscriptionPayments: { path: "/subscription-payments" },
  Vendors: { path: "/vendors" },
  Inventory: { path: "/inventory" },
  Workers: { path: "/workers" },
  BootstrapTables: { path: "/tables/bootstrap-tables" },
  Billing: { path: "/billing" },
  Invoice: { path: "/invoice" },
  Signin: { path: "/sign-in" },
  RequestDemo: { path: "/request-demo" },
  Signup: { path: "/sign-up" },
  ForgotPassword: { path: "/forgot-password" },
  ResetPassword: { path: "/reset-password" },
  Lock: { path: "/lock" },
  NotFound: { path: "/404" },
  ServerError: { path: "/500" },

  // components
  Accordions: { path: "/components/accordions" },
  Alerts: { path: "/components/alerts" },
  Badges: { path: "/components/badges" },
  Widgets: { path: "/components/widgets" },
  Breadcrumbs: { path: "/components/breadcrumbs" },
  Buttons: { path: "/components/buttons" },
  Forms: { path: "/components/forms" },
  Modals: { path: "/components/modals" },
  Navs: { path: "/components/navs" },
  Navbars: { path: "/components/navbars" },
  Pagination: { path: "/components/pagination" },
  Popovers: { path: "/components/popovers" },
  Progress: { path: "/components/progress" },
  Tables: { path: "/components/tables" },
  TablesComps: { path: "/components/tables-comps" },
  Tabs: { path: "/components/tabs" },
  Tooltips: { path: "/components/tooltips" },
  Toasts: { path: "/components/toasts" },
  WidgetsComponent: { path: "/components/widgets" },
};
