import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

// core styles
import "./scss/ag-app.scss";

// vendor styles
import "react-datetime/css/react-datetime.css";

import Main from "./pages/Main";

ReactDOM.render(
  <BrowserRouter>
    <Main />
  </BrowserRouter>,
  document.getElementById("root")
);
